import { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import useStore from '../utils/store'

const Header = () => {
  const location = useLocation()
  const [active, setActive] = useState(false)
  const { count } = useStore()

  useEffect(() => {
    const body = document.querySelector('body')
    if (active) body.classList.add('active')
    if (!active) body.classList.remove('active')
  }, [active])

  return (
    <>
      <div className='mobHeader'>
        <Link onClick={() => setActive(false)} to='/'>
          <img src={require('../img/logo1.png')} alt='logo' />
        </Link>
        <div onClick={() => setActive(!active)} className={`navbtn ${active ? 'active' : ''}`}>
          <div className='lan'></div>
        </div>
      </div>
      <div className={`darkness ${active ? 'active' : ''}`} onClick={() => setActive(false)} />
      <header className={`${location.pathname === '/egoplast' && 'whiteText'} ${active ? 'active' : ''} `}>
        <div className='content flex sb'>
          <Link onClick={() => setActive(false)} to='/'>
            <img src={location.pathname === '/egoplast' ? require('../img/logo1.png') : require('../img/logo.png')} alt='logo' />
          </Link>
          <ul>
            <li>
              <NavLink onClick={() => setActive(false)} className={({ isActive }) => (isActive ? 'active' : '')} to='/'>
                Главная
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => setActive(false)} className={({ isActive }) => (isActive ? 'active' : '')} to='/catalog'>
                Каталог
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => setActive(false)} className={({ isActive }) => (isActive ? 'active' : '')} to='/services'>
                Услуги
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => setActive(false)} className={({ isActive }) => (isActive ? 'active' : '')} to='/egoplast'>
                О компании
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => setActive(false)} className={({ isActive }) => (isActive ? 'active' : '')} to='/contacts'>
                Контакты
              </NavLink>
            </li>
          </ul>
          <NavLink to='/catalog/basket' onClick={() => setActive(false)} className='basket'>
            <p>
              Корзина <span>{count}</span>
            </p>
          </NavLink>
        </div>
      </header>
    </>
  )
}

export default Header
