import { useState } from 'react'
import { Image } from 'antd'
// import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
// import { FIND_MANY_FAQ } from '../../gqls/FAQ/query'
import { useQuery } from '@apollo/client'
// import Loader from '../../components/Loader'
import { FIND_MANY_ALBOM } from '../../gqls/album/query'
// const { Panel } = Collapse

const Egoplast = () => {
  const navigate = useNavigate()
  const [page, setpage] = useState(0)

  // const { data, loading } = useQuery(FIND_MANY_FAQ, {
  //   variables: { where: { company: { equals: 'EGOPLAST' } } },
  //   fetchPolicy: 'network-only',
  // })

  const { data: dataAlbum } = useQuery(FIND_MANY_ALBOM, {
    variables: { where: { company: { equals: 'EGOPLAST' } } },
    fetchPolicy: 'network-only',
  })

  return (
    <>
      <section className='full ego'>
        <div className='bg egobg' />
        <div className='content'>
          <h3>Эгопласт</h3>
          <h1>
            Полиэтиленовые <br /> трубы и Пластиковые <br />
            изделия в Якутии
          </h1>
          <p style={{ maxWidth: 576 }}>
            Завод по производству полиэтиленовых труб и полозий для снегоходов. Производство емкостей из пластика: септики, водовозки и различные
            изделия из пластика
          </p>
          <div style={{ marginBottom: 40 }} className='flex wrap'>
            <button onClick={() => navigate('/services')}>Оставить заявку</button>
            <button onClick={() => navigate('/catalog')}>Каталог</button>
          </div>
          <div className='flex wrap stat'>
            <div className='quantity'>
              <div className='flex'>
                <p>338</p>
              </div>
              <p>
                и более километров <br />
                труб ежегодно
              </p>
            </div>
            <div className='quantity'>
              <div className='flex'>
                <p>10</p>
              </div>
              <p>
                лет опыта в <br /> своей сфере
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className='content'>
        <div className='flex pageButtons wrap'>
          <button onClick={() => setpage(0)} className={page === 0 ? 'active' : ''}>
            О компании
          </button>
          <button onClick={() => setpage(1)} className={page === 1 ? 'active' : ''}>
            Производство
          </button>
          <button onClick={() => setpage(2)} className={page === 2 ? 'active' : ''}>
            Фотоальбом
          </button>
          <button onClick={() => setpage(3)} className={page === 3 ? 'active' : ''}>
            Сертификаты
          </button>
        </div>
        {page === 1 && (
          <>
            <section style={{ marginTop: 60 }}>
              <div className='textbox'>
                <h2>В глубины нашего производства</h2>
                <p style={{ maxWidth: 650 }}>
                  Наше производство находится в г. Якутске. Работаем на рынке более 10 лет.
                  <br /> За этот период зарекомендовали себя как надежный производитель, выпускающий продукцию высокого качества, отмеченную
                  сертификатами ГОСТ.
                  <br /> Производим полиэтиленовые трубы только из высококачественного полиэтилена трубных марок. Для усовершенствования наших
                  продуктов сотрудничаем с "Институтом проблем нефти и газа Сибирского отделения Российской академии наук" — они производят апробацию
                  специальных добавок.
                  <br /> Производство полиэтиленовых труб — одно из самых экологически чистых производств в мире.
                  <br />В любое время года можем поставлять необходимый объем продукции нашим клиентам. Нам доверяют более 250 сел республики и г.
                  Якутск.
                </p>
                <br />
                <p style={{ maxWidth: 570, marginBottom: 5 }}>С 2013 по 2023 гг.:</p>
                <ul style={{ marginLeft: 25, marginBottom: 5 }}>
                  <li>
                    <p>произведено более 3 500 км. труб;</p>
                  </li>
                  <li>
                    <p style={{ maxWidth: 620 }}>
                      в более 250 населенных пунктах РС (Я) смонтированы водопроводы и проведены газопроводы из наших труб.
                    </p>
                  </li>
                </ul>
                <p style={{ maxWidth: 570, marginBottom: 5 }}>С 2021 по 2023 г. произведено более 15 000 м³ емкостей и септиков.</p>
              </div>
            </section>
            <section className='ai_fs flex wrap' style={{ marginTop: 100 }}>
              <div className='textbox'>
                <h2>О производстве полиэтиленовых труб</h2>
                <p style={{ maxWidth: 650, marginBottom: 5 }}>
                  "Эгопласт" не использует вторичное сырье и продукты переработки полиэтилена - только первичный материал.
                  <br /> Первичный ПЭ стоек к механическим воздействиям, УФ-излучению и обеспечивает стабильную эксплуатацию в условиях Крайнего
                  Севера. Трубы "Эгопласт" устойчивы до -60°, не треснут и не лопнут при замерзании жидкостей. <br />
                  Мы - в Якутии. Мы знаем требования республики так, как ни один поставщик за ее пределами. Фирменная гарантия "Эгопласт" - это
                  гарантия землякам. <br /> Полиэтиленовые трубы - оптимальны. <br /> Для водопроводов
                  <br /> А также устройства ливневых коллекторов, дренажных систем, систем водоотвода, мелиорации и орошения - всего, что связано с
                  водой. <br /> Для газопроводов <br /> Рекомендованы к применению для магистральных и локальных сетей газоснабжения - как в жилых
                  зданиях, так и для производственных объектов. <br /> Для канализации и септиков <br /> Применяются в трубопроводных канализационных
                  системах. Надежные инженерные коммуникации - для сложных условий, но за разумные деньги. <br /> Для защиты электрокабелей <br />
                  Используются в качестве труб-оболочек при прокладке скрытых и подземных кабель-каналов - эффективно защищают от внешних воздействий.
                </p>
                <p style={{ maxWidth: 650, marginBottom: 5 }}>Преимущества труб:</p>
                <ul style={{ marginLeft: 25, marginBottom: 5 }}>
                  <li>
                    <p>ударопрочность;</p>
                  </li>
                  <li>
                    <p>морозостойкость (до -60);</p>
                  </li>
                  <li>
                    <p>долгий срок эксплуатации;</p>
                  </li>
                  <li>
                    <p>легкий вес при транспортировке;</p>
                  </li>
                  <li>
                    <p>невозможность поломки при замерзании воды в трубе;</p>
                  </li>
                  <li>
                    <p>упрощение строительства за счет гибкости, эластичности и легкости веса;</p>
                  </li>
                  <li>
                    <p>удобство и безопасность при монтажных работах;</p>
                  </li>
                  <li>
                    <p>
                      при использовании труб бухтами (100-300 м.) и труб в отрезках (16 м.) снижение расходов и увеличение скорости прокладки за счет
                      снижения количества стыков.
                    </p>
                  </li>
                </ul>
              </div>
              <div className='catalogCard'>
                <div>
                  <img alt='' src={require('../../img/catalogcard1.png')} />
                  <h2>Полиэтиленовые трубы</h2>
                  <button onClick={() => navigate('/catalog')}>Каталог</button>
                </div>
              </div>
            </section>
            <section style={{ marginTop: 100 }}>
              <div>
                <h2 style={{ marginBottom: 30 }}>Коротко о производстве</h2>
                <div className='flex wrap ai_fs'>
                  <video muted autoPlay loop controls src={require('../../img/egoplast.mp4')} />
                </div>
              </div>
            </section>
            <section className='ai_fs flex wrap' style={{ marginTop: 100 }}>
              <div className='textbox'>
                <h2>Полозья для саней и буранов</h2>
                <p style={{ maxWidth: 650, marginBottom: 5 }}>
                  С 2015 года начала выпускать полиэтиленовые полозья для саней снегохода, которые очень востребованы в северных улусах республики.
                  Толщина полозьев от 1 до 4 см. <br /> <br />
                  Кроме того, по просьбе официального дилера вездехода «Арго» разработали гусеницы для вездехода и других внедорожников. Проведены
                  ходовые испытания в полевых условиях, которые прошли успешно. <br /> <br />
                  Гусеницы в два раза легче заводских резиновых, вследствие чего повысилась проходимость по болотистой местности, воде и снегу.
                  Уменьшились нагрузки на ходовую часть за счет уменьшения веса и отсутствия дополнительных выносных проставок. Мы видим перспективу
                  использования данных видов гусениц и для других внедорожников. Можем изготовить под любой размер колес различных вездеходов и
                  автомобилей и тем самым запустить массовый выпуск для всех видов внедорожников.
                  <br />
                </p>
                <p style={{ maxWidth: 650, marginBottom: 5 }}>Полозья для саней снегоходов:</p>
                <ul style={{ marginLeft: 25, marginBottom: 5 }}>
                  <li>
                    <p>стандартные размеры по 4, 5, 6 и 7 м. (всегда в наличии);</p>
                  </li>
                  <li>
                    <p>индивидуальный заказ;</p>
                  </li>
                  <li>
                    <p>полиэтиленовые трубы для изготовления саней;</p>
                  </li>
                </ul>
              </div>
              <div className='catalogCard'>
                <div>
                  <img alt='' src={require('../../img/catalogcard2.png')} />
                  <h2>Полозья для саней и буранов</h2>
                  <button onClick={() => navigate('/catalog')}>Каталог</button>
                </div>
              </div>
            </section>
            <section className='ai_fs flex wrap' style={{ marginTop: 100 }}>
              <div className='textbox'>
                <h2>Фитинги и комплектующие для труб</h2>
                <p style={{ maxWidth: 650 }}>
                  1. Компрессионные фитинги. Отличительная особенность компрессионных фитингов — максимальная простота монтажа (нет необходимости в
                  проведении ряда подготовительных процедур, необязателен ряд специальных навыков), соединение происходит без сварочного аппарата, тем
                  не менее использование компрессионных фитингов позволяет использовать при рабочих давления до 16 атмосфер. <br />
                  Ещё один очевидный плюс компрессионных фитингов — данный вид соединений является разборным, что при проведении ремонтных работ
                  позволяет быстро осуществить демонтаж и замену необходимых деталей. Муфты, отводы, тройники, соединения с внутренней и наружной
                  резьбами, переходы, седелки, фланцевые соединения, шаровые краны на цанговых зажимах для сборно-разборных трубопроводов, не
                  требующие использования специального оборудования для трубопроводов хозяйственно-питьевого водоснабжения диаметром от 20 до 110 мм.{' '}
                  <br />
                  <br />
                  2. Сварные фитинги (литые). Это конструкции, которые изготавливаются из частей труб и позволяют монтировать оборудование в различных
                  сложнейших ситуациях. Современное оборудование позволяет изготавливать такие сварные фитинги с минимальным участием человека, на
                  автоматизированных линиях. <br />
                  <br />
                  3. Электросварные фитинги. Электросварные фитинги — вид соединительных деталей, которые в процессе производства оснащаются
                  нагревательным элементом в виде спирали. Сварка производится путём нагревания закладного нагревателя путём протекания электрического
                  тока и дальнейшего оплавления материала контактирующих поверхностей, что приводит к появлению герметичной области. <br />
                  <br />
                  4. Комбинированные фитинги. По различным технологическим требованиям не везде допускается применение полиэтиленовых труб. И
                  возникает необходимость создания переходов от полиэтилена к металлу. Для этого применяются фитинги, в которых уже в заводских
                  условиях соединены эти разные по своим свойствам материалы. Такие фитинги могут соединять сталь и полиэтилен. Латунь, медь и
                  полиэтилен. Вариантов очень много. <br />
                  Комбинированные фитинги выпускаются на все диаметры производимых труб.
                </p>
              </div>
              <div className='catalogCard'>
                <div>
                  <img alt='' src={require('../../img/catalogcard3.png')} />
                  <h2>Фитинги и комплектующие</h2>
                  <button onClick={() => navigate('/catalog')}>Каталог</button>
                </div>
              </div>
            </section>
            <section style={{ marginTop: 100 }}>
              <div className='textbox'>
                <h2>Информация о производстве</h2>
                <p style={{ maxWidth: 620 }}>
                  Емкости:
                  <ul style={{ marginLeft: 25, marginBottom: 10 }}>
                    <li>стандартные размеры от 0,5 до 4 м³ (всегда в наличии);</li>
                    <li>индивидуальный заказ (от 100 л. до 50 м³);</li>
                    <li>для орошения огородов;</li>
                    <li>летние и всесезонные;</li>
                    <li>разнообразие цветовой гаммы;</li>
                    <li>любая форма;</li>
                  </ul>
                  Септики:
                  <ul style={{ marginLeft: 25, marginBottom: 10 }}>
                    <li>стандартные размеры (всегда в наличии);</li>
                    <li>индивидуальный заказ;</li>
                    <li>разнообразие цветовой гаммы;</li>
                    <li>любая форма;</li>
                  </ul>
                  Бассейны:
                  <ul style={{ marginLeft: 25, marginBottom: 10 }}>
                    <li>стандартные размеры (всегда в наличии);</li>
                    <li>индивидуальный заказ;</li>
                    <li>разнообразие цветовой гаммы;</li>
                    <li>любая форма;</li>
                  </ul>
                  Лодки из полиэтилена:
                  <ul style={{ marginLeft: 25, marginBottom: 10 }}>
                    <li>Тугун-370 (длина – 3,70 м., ширина – 1,5 м., вес – 115 кг.);</li>
                    <li>Север-420 (длина – 4,20 м., ширина – 1,75 м. вес – 240 кг.);</li>
                    <li>Крым-475 (длина – 4,75 м., ширина – 1,6 м., вес – 190 кг.);</li>
                    <li>Прогресс (длина – 4,75 м., ширина – 1,7 м., вес – 270 кг.);</li>
                    <li>Лодка-8,5М с защитой винта (длина – 8,5 м., ширина – 1,2 м., вес – 190 кг.);</li>
                    <li>Лимба-500 (длина – 5,00 м., ширина – 1,6 м., вес – 260 кг.);</li>
                    <li>Аэролодка (длина – 3,20 м., ширина – 1,2 м., вес – 150 кг.);</li>
                    <li>Индивидуальный заказ по размерам заказчиков;</li>
                    <li>СЕРТИФИКАЦИЯ В ГИМС;</li>
                  </ul>
                  Дебаркадеры, понтоны:
                  <ul style={{ marginLeft: 25, marginBottom: 10 }}>
                    <li>любые размеры;</li>
                    <li>индивидуальный заказ;</li>
                    <li>любая форма по желанию клиента;</li>
                  </ul>
                </p>
              </div>
            </section>
            <section style={{ marginTop: 100 }}>
              <div className='textbox'>
                <h2>Автономные системы водоотведения</h2>
                <p style={{ maxWidth: 620 }}>
                  Нами разработана уникальная автономная система водоотведения. Система решит проблему образования луж на дорогах и придворовых
                  территориях в населенных пунктах. Данная система прошла апробацию на участке Дежнева-Автодорожная в г.Якутске в 2015 г, и
                  Сергеляхском шоссе 12 км., дом.3, доказала свою эффективность. Проект был лучшим по итогам года благоустройства в г.Якутске.
                  Зарегистрирован патент.
                  <br />
                  <br />
                  Области применения:
                  <ul style={{ marginLeft: 25, marginBottom: 5 }}>
                    <li>водоотведение талых и ливневых вод на дорогах, придворовых территориях населенных пунктов;</li>
                    <li>
                      откачка воды в болотистых местностях, на местах скопления талых вод независимо от рельефа местности и естественных уклонов;
                    </li>
                    <li>для орошения огородов;</li>
                  </ul>
                  <br />
                  Уникальность проекта:
                  <ul style={{ marginLeft: 25, marginBottom: 5 }}>
                    <li>полная автономность работы;</li>
                    <li>уменьшение затрат на откачку сточных вод;</li>
                    <li>Надежность и долговечность за счет ПЭ труб, которые, находясь под землей, служат 50 лет</li>
                    <li>Малая затратность при эксплуатации</li>
                    <li>Мобильность</li>
                    <li>Независимость от уровня земли, уменьшения нефтяных продуктов</li>
                    <li>Энергосбережение за счет солнечных панелей</li>
                    <li>
                      Многофункциональность (может использоваться для откачки сточных вод, маленьких озер, образующихся из - за сильных ливней,
                      откачки грунтовых вод). При небольшой модернизации можно использовать в сетях летнего водопровода, орошения огородов.
                    </li>
                  </ul>
                </p>
              </div>
            </section>
          </>
        )}
        {page === 2 && (
          <>
            <section className='ai_fs flex wrap album' style={{ marginTop: 60, justifyContent: 'flex-start' }}>
              <Image.PreviewGroup>
                {dataAlbum?.findManyAlbom.map((item) => (
                  <Image key={item.id} src={item.image} className='albumcard' />
                ))}
              </Image.PreviewGroup>
            </section>
          </>
        )}
        {page === 3 && (
          <>
            <section className='ai_fs wrap' style={{ marginTop: 60 }}>
              <div className='sbtype1'>
                <h2>Сертификаты</h2>
                <div className='flex wrap certificate'>
                  <Image.PreviewGroup>
                    <Image src={require('../../img/doc/doc-ego-1.png')} />
                    <Image src={require('../../img/doc/doc-ego-2.png')} />
                    <Image src={require('../../img/doc/doc-ego-3.png')} />
                    <Image src={require('../../img/doc/doc-ego-4.png')} />
                    <Image src={require('../../img/doc/doc-techno-1.png')} />
                    <Image src={require('../../img/doc/doc-techno-2.png')} />
                    <Image src={require('../../img/doc/doc-techno-3.png')} />
                  </Image.PreviewGroup>
                </div>
              </div>
            </section>
          </>
        )}
        {page === 0 && (
          <>
            <section className='ai_fs flex wrap' style={{ gap: 60, marginTop: 100 }}>
              <div className='sbtype1'>
                <h1>О компании "Эгопласт"</h1>
                <p style={{ maxWidth: 570, marginBottom: 5 }}>Помимо производства полиэтиленовых труб создаем проектно-сметные расчеты:</p>
                <ul style={{ marginLeft: 25, marginBottom: 5 }}>
                  <li>
                    <p>локальных водопроводов</p>
                  </li>
                  <li>
                    <p>систем водоотведения</p>
                  </li>
                  <li>
                    <p>систем водоснабжения</p>
                  </li>
                  <li>
                    <p>оросительных систем и.т.д.</p>
                  </li>
                </ul>
                <p style={{ maxWidth: 570, marginBottom: 30 }}>
                  В услуги входят расчет гидравлики и трассировки, отбор и поставка насосного оборудования.
                  <br />
                  <br />
                  Так же мы изготавливаем: емкости, септики, бассейны, ванны, жироуловители, урны и другие изделия из пластика по размерам заказчика с
                  учетом всех пожеланий.
                </p>
                <div className='flex wrap stat'>
                  <div className='quantity'>
                    <div className='flex'>
                      <p>400</p>
                    </div>
                    <p>
                      и более <br />
                      благоустроенных домов
                    </p>
                  </div>
                  <div className='quantity'>
                    <div className='flex'>
                      <p>10</p>
                    </div>
                    <p>
                      лет опыта в <br /> своей сфере
                    </p>
                  </div>
                </div>
              </div>
              <img src={require('../../img/krupa.png')} alt='' />
            </section>
            <section style={{ display: 'block' }}>
              <div className='flex'>
                <hr />
                <h1>Почему мы?</h1>
                <hr />
              </div>
              <div id='center' className='flex wrap' style={{ gap: 40, marginTop: 90, justifyContent: 'space-evenly' }}>
                <div className='iconcard'>
                  <div className='icon'>
                    <img src={require('../../img/stack.png')} alt='' />
                  </div>
                  <h3>Сырье</h3>
                  <p>
                    При производстве используем <br /> только первичное сырье
                  </p>
                </div>
                <div className='iconcard'>
                  <div className='icon'>
                    <img src={require('../../img/key.png')} alt='' />
                  </div>
                  <h3>Модернизация производства</h3>
                  <p>
                    Постоянное совершенствование <br /> производства
                  </p>
                </div>
                <div className='iconcard'>
                  <div className='icon'>
                    <img src={require('../../img/line.png')} alt='' />
                  </div>
                  <h3>Гарантия 3 года</h3>
                  <p>
                    На все стандартные <br /> емкости (сохранить чек)
                  </p>
                </div>
              </div>
            </section>
            <section className='ai_fe wrap' style={{ paddingTop: 100 }}>
              <div className='sbtype1'>
                <h3>Эгопласт</h3>
                <h1 style={{ maxWidth: 300 }}>Лучшие в своем деле</h1>
              </div>
              <div style={{ width: 50 }} />
              <div className='sbtype1'>
                <p style={{ maxWidth: 570, marginBottom: 30 }}>
                  Мы изготавливаем: емкости, септики, бассейны, ванны, жироуловители, урны и другие изделия из пластика по размерам заказчика с учетом
                  всех пожеланий.
                </p>
              </div>
            </section>
            <section className='ai_fs flex'>
              <img src={require('../../img/bochka.png')} alt='' />
              <div className='sbtype1'>
                <h1>Эгопласт</h1>
                <p style={{ maxWidth: 570 }}>
                  «Эгопласт» в Якутске предоставляет услуги и товары в сфере пленочного пола, теплообменного оборудования, циркуляционного насоса,
                  монтажа теплых полов, емкостей из полипропилена, резервного электроснабжения, проточных водонагревателей.
                </p>
              </div>
            </section>
            <section style={{ marginTop: 100 }}>
              <div>
                <h1 style={{ marginBottom: 30 }}>Презентационный ролик</h1>
                <div className='flex wrap ai_fs'>
                  <video muted autoPlay loop controls src={require('../../img/egoplast.mp4')} />
                  {/* <p className='videotext'>Если бы мы вышли на IPO, то захватили бы весь север Американского рынка.</p> */}
                </div>
              </div>
            </section>
            <section style={{ marginTop: 100 }}>
              <div>
                <h1 style={{ marginBottom: 30 }}>Руководство</h1>
                <div className='flex wrap ai_fs workers'>
                  <div className='worker'>
                    <img src={require('../../img/director.png')} alt='' />
                    <h4>Соловьев Михаил Николаевич</h4>
                    <p>Генеральный директор компании "Эгопласт"</p>
                  </div>
                  <div className='worker'>
                    <img src={require('../../img/director3.jpg')} alt='' />
                    <h4>Уваров Аян Николаевич</h4>
                    <p>Исполнительный директор</p>
                  </div>
                  <div className='worker'>
                    <img src={require('../../img/lukinpng.png')} alt='' />
                    <h4>Лукин Эдуард Кононович</h4>
                    <p>Главный инженер</p>
                  </div>
                  <div className='worker'>
                    <img src={require('../../img/director1.png')} alt='' />
                    <h4>Колодезников Руслан Петрович</h4>
                    <p>Руководитель цеха по производству емкостей, лодок и изделий из полиэтилена и пластика</p>
                  </div>
                  <div className='worker'>
                    <img src={require('../../img/lukinpng1.png')} alt='' />
                    <h4>Слепцов Иван Васильевич</h4>
                    <p>Управляющий сбытом</p>
                  </div>
                </div>
              </div>
            </section>
            {/* <section style={{ display: 'block' }}>
            <div className='flex'>
              <hr />
              <h1>Частые вопросы</h1>
              <hr />
            </div>
            <div className='flex jc_c' style={{ margin: 20 }}>
              <p style={{ textAlign: 'center', maxWidth: 550 }}>Дополнительно можете задать вопросы во время обратной связи</p>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <Collapse
                style={{ maxWidth: 1000, margin: 'auto', fontFamily: 'GilroyBold', fontSize: 18 }}
                ghost
                expandIconPosition='end'
                expandIcon={(props) =>
                  props.isActive ? <MinusCircleOutlined style={{ fontSize: 20 }} /> : <PlusCircleOutlined style={{ fontSize: 20 }} />
                }
              >
                {data?.findManyFAQ.map((item) => (
                  <Panel header={item.title} key={item.id}>
                    <p>{item.description}</p>
                  </Panel>
                ))}
              </Collapse>
            )}
          </section> */}
          </>
        )}
      </div>
    </>
  )
}

export default Egoplast
