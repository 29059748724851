import { gql } from '@apollo/client'

export const FIND_MANY_NEWS = gql`
  query {
    findManyNews {
      id
      title
      description
      image
    }
  }
`
export const FIND_ONE_NEWS = gql`
  query ($where: NewsWhereUniqueInput!) {
    findUniqueNews(where: $where) {
      id
      title
      description
      image
      createdAt
    }
  }
`
