const pipesPrices = [
  {
    type: 'SDR 26 - 6,3 атм.',
    values: [
      {
        d: 50,
        c: 95.46,
      },
      {
        d: 63,
        c: 150.88,
      },
      {
        d: 90,
        c: 298.69,
      },
      {
        d: 110,
        c: 437.25,
      },
      {
        d: 160,
        c: 933.02,
      },
      {
        d: 200,
        c: 1441.09,
      },
      {
        d: 225,
        c: 1810.61,
      },
      {
        d: 250,
        c: 2244.78,
      },
      {
        d: 280,
        c: 2799.05,
      },
      {
        d: 315,
        c: 3571.94,
      },
      {
        d: 355,
        c: 4495.72,
      },
      {
        d: 400,
        c: 5727.42,
      },
      {
        d: 450,
        c: 7236.26,
      },
      {
        d: 500,
        c: 8929.85,
      },
      {
        d: 630,
        c: 14164.6,
      },
    ],
  },
  {
    type: 'SDR 21 - 8 атм.',
    values: [
      {
        d: 40,
        c: 88.9,
      },
      {
        d: 50,
        c: 113.93,
      },
      {
        d: 63,
        c: 175.52,
      },
      {
        d: 90,
        c: 363.35,
      },
      {
        d: 110,
        c: 545.03,
      },
      {
        d: 160,
        c: 1142.41,
      },
      {
        d: 200,
        c: 1776.73,
      },
      {
        d: 225,
        c: 2244.78,
      },
      {
        d: 250,
        c: 2746.7,
      },
      {
        d: 280,
        c: 3479.56,
      },
      {
        d: 315,
        c: 4372.55,
      },
      {
        d: 355,
        c: 5542.67,
      },
      {
        d: 400,
        c: 7051.51,
      },
      {
        d: 450,
        c: 8929.85,
      },
      {
        d: 500,
        c: 11023.75,
      },
      {
        d: 630,
        c: 17397.82,
      },
    ],
  },
  {
    type: 'SDR 17,6 - 9.5 атм.',
    values: [
      {
        d: 40,
        c: 101.22,
      },
      {
        d: 50,
        c: 134.26,
      },
      {
        d: 63,
        c: 210.01,
      },
      {
        d: 90,
        c: 431.1,
      },
      {
        d: 110,
        c: 637.41,
      },
      {
        d: 160,
        c: 1339.48,
      },
      {
        d: 200,
        c: 2087.74,
      },
      {
        d: 225,
        c: 2632.77,
      },
      {
        d: 250,
        c: 3264.02,
      },
      {
        d: 280,
        c: 4064.62,
      },
      {
        d: 315,
        c: 5142.36,
      },
      {
        d: 355,
        c: 6528.03,
      },
      {
        d: 400,
        c: 8283.21,
      },
      {
        d: 450,
        c: 10469.48,
      },
      {
        d: 500,
        c: 12932.89,
      },
      {
        d: 630,
        c: 20507.87,
      },
    ],
  },
  {
    type: 'SDR 17 - 10 атм.',
    values: [
      {
        d: 32,
        c: 73.51,
      },
      {
        d: 40,
        c: 104.3,
      },
      {
        d: 50,
        c: 139.49,
      },
      {
        d: 63,
        c: 222.32,
      },
      {
        d: 90,
        c: 450.8,
      },
      {
        d: 110,
        c: 671.28,
      },
      {
        d: 160,
        c: 1401.99,
      },
      {
        d: 200,
        c: 2167.8,
      },
      {
        d: 225,
        c: 2752.86,
      },
      {
        d: 250,
        c: 3264.02,
      },
      {
        d: 280,
        c: 4249.38,
      },
      {
        d: 315,
        c: 5357.91,
      },
      {
        d: 355,
        c: 6835.96,
      },
      {
        d: 400,
        c: 8621.93,
      },
      {
        d: 450,
        c: 10931.37,
      },
      {
        d: 500,
        c: 13517.95,
      },
      {
        d: 630,
        c: 21431.65,
      },
    ],
  },
  {
    type: 'SDR 13,6 - 12,5 атм.',
    values: [
      {
        d: 25,
        c: 56.19,
      },
      {
        d: 32,
        c: 108.54,
      },
      {
        d: 40,
        c: 117.77,
      },
      {
        d: 50,
        c: 167.51,
      },
      {
        d: 63,
        c: 267.28,
      },
      {
        d: 90,
        c: 541.33,
      },
      {
        d: 110,
        c: 811.39,
      },
      {
        d: 160,
        c: 1709.61,
      },
      {
        d: 200,
        c: 2635.85,
      },
      {
        d: 225,
        c: 3356.39,
      },
      {
        d: 250,
        c: 4126.21,
      },
      {
        d: 280,
        c: 5173.16,
      },
      {
        d: 315,
        c: 6558.82,
      },
      {
        d: 355,
        c: 8314.0,
      },
      {
        d: 400,
        c: 10531.07,
      },
      {
        d: 450,
        c: 13333.2,
      },
      {
        d: 500,
        c: 16474.04,
      },
      {
        d: 630,
        c: 26112.13,
      },
    ],
  },
  {
    type: 'SDR 11 - 16 атм.',
    values: [
      {
        d: 20,
        c: 70.43,
      },
      {
        d: 25,
        c: 93.52,
      },
      {
        d: 32,
        c: 144.33,
      },
      {
        d: 40,
        c: 198.61,
      },
      {
        d: 50,
        c: 204.15,
      },
      {
        d: 63,
        c: 326.4,
      },
      {
        d: 90,
        c: 660.19,
      },
      {
        d: 110,
        c: 976.13,
      },
      {
        d: 160,
        c: 2073.27,
      },
      {
        d: 200,
        c: 3202.43,
      },
      {
        d: 225,
        c: 4064.62,
      },
      {
        d: 250,
        c: 4988.4,
      },
      {
        d: 280,
        c: 6250.9,
      },
      {
        d: 315,
        c: 7913.7,
      },
      {
        d: 355,
        c: 10038.39,
      },
      {
        d: 400,
        c: 12748.14,
      },
      {
        d: 450,
        c: 16135.32,
      },
      {
        d: 500,
        c: 19922.81,
      },
      {
        d: 630,
        c: 31624.0,
      },
    ],
  },
]
export default pipesPrices
