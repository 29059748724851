import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import Loader from '../../components/Loader'
import TodayDate from '../../components/TodayDate'
import { FIND_ONE_NEWS } from '../../gqls/news/query'

const Event = () => {
  const { id } = useParams()
  const { data, loading } = useQuery(FIND_ONE_NEWS, { fetchPolicy: 'network-only', variables: { where: { id } } })
  if (loading)
    return (
      <div className='content'>
        <section className='full'>
          <div style={{ width: '100%' }}>
            <Loader />
          </div>
        </section>
      </div>
    )
  const news = data?.findUniqueNews
  return (
    <div className='content'>
      <section className='yetfull'>
        <div className='event'>
          {!data ? (
            'Ошибка. Попробуйте позже.'
          ) : (
            <>
              <div>Главная — События</div>
              <h1>{news.title}</h1>
              <div className='date'>{TodayDate(news.createdAt, true)}</div>
              {news.description.split('\n').map((item, index) => (
                <p key={index}>{item}</p>
              ))}
              <img alt='' style={{ width: '100%' }} src={news.image} />
            </>
          )}
        </div>
      </section>
    </div>
  )
}

export default Event
