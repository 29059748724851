import { useMutation, useQuery } from '@apollo/client'
import { Checkbox, message } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../components/Loader'
import { FIND_MANY_NEWS } from '../gqls/news/query'
import lilcard1 from '../img/lilcard1.png'
import lilcard2 from '../img/lilcard2.png'
import lilcard3 from '../img/lilcard3.png'
import { SEND_EMAIL } from '../gqls/email/mutation'
import { useState } from 'react'
import { MaskedInput } from 'antd-mask-input'

const Home = () => {
  const navigate = useNavigate()
  const { data, loading } = useQuery(FIND_MANY_NEWS, { fetchPolicy: 'network-only' })
  const [agree, setAgree] = useState(false)

  const [sendEmail, { loading: loadingEmail }] = useMutation(SEND_EMAIL, {
    onCompleted: () => message.open({ type: 'success', content: 'Сообщение отправлено! Вам скоро ответят.' }),
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const emailSend = (e) => {
    e.preventDefault()
    const { name, tel, email, question } = e.target
    if (!agree) {
      message.open({ type: 'warning', content: 'Необходимо согласие на обработку данных.' })
      return null
    }
    if (!loadingEmail && name.value && tel.value && email.value && question.value) {
      sendEmail({
        variables: {
          data: {
            title: 'Запрос на обратную связь + вопрос (Главная)',
            description: `Имя: ${name.value}\nТелефон: ${tel.value}\nПочта: ${email.value}\nВопрос:\n"${question.value}".\n\nЗапрос был сделан в окне "Главная".`,
          },
        },
      })
      e.target.reset()
      tel.value = ''
    } else {
      message.open({ type: 'warning', content: 'Заполните все поля.' })
    }
  }

  return (
    <>
      <section className='full jc_c'>
        <div className='bg homebg' />
        <div className='content' style={{ maxWidth: 678, textAlign: 'center' }}>
          <h1 style={{ fontSize: 65 }}>
            Найдите <span style={{ color: '#3859D6' }}>пластик</span> для своего быта
          </h1>
          <p>Компания "Эгопласт" занимается производством и изготовлением полимерных изделий</p>
          <div style={{ marginBottom: 40 }} className='flex jc_c wrap'>
            <button onClick={() => navigate('/catalog')}>Каталог</button>
            <button
              onClick={() => navigate('/services')}
              style={{ color: '#3859D6', borderWidth: 2, borderColor: '#3859D6', borderStyle: 'solid', background: 'none' }}
            >
              Оставить заявку
            </button>
          </div>
        </div>
      </section>
      <div className='content home'>
        <div style={{ marginTop: 70, marginBottom: 90 }}>
          <h1 style={{ textAlign: 'center', marginBottom: 50 }}>Предоставляемый каталог</h1>
          <div className='homecards flex wrap'>
            <Link to='/catalog/pipes' className='lilcard' style={{ backgroundImage: `url(${lilcard1})` }}>
              <h2>ПОЛИЭТИЛЕНОВЫЕ ТРУБЫ</h2>
              <img alt='' width={25} src={require('../img/arrow.png')} />
            </Link>
            <Link to='/catalog/round-and-oval-containers' className='lilcard' style={{ backgroundImage: `url(${lilcard2})` }}>
              <h2>ЕМКОСТИ</h2>
              <img alt='' width={25} src={require('../img/arrow.png')} />
            </Link>
            <Link to='/catalog/' className='lilcard' style={{ backgroundImage: `url(${lilcard3})` }}>
              <h2>ВЕСЬ КАТАЛОГ</h2>
              <img alt='' width={25} src={require('../img/arrow.png')} />
            </Link>
          </div>
        </div>
        <section className='wrap ai_c' style={{ gap: 50 }}>
          <div className='texttype1' style={{ flex: '1 1 calc((100% - 100px) / 2)' }}>
            <h1 style={{ marginBottom: 50, maxWidth: 300 }}>В чем наши преимущества?</h1>
            <h3>
              <span>01.</span> Наш план
            </h3>
            <p>Высококвалифицированные специалисты, профессионалы своего дела, применяющие современные методы и подходы в инженерном деле.</p>
            <h3>
              <span>02.</span> Качество
            </h3>
            <p>Качество труб подтверждено сертификатом по ГОСТ 18599-2011 Для напорных труб и водопроводов и ГОСТ Р 50838-2009 Для газопроводов.</p>
            <h3>
              <span>03.</span> Миссия
            </h3>
            <p>Миссия компании: “Нести комфорт и благоустройство в условиях крайнего севера”.</p>
            <h3>Преимущества работы с нами:</h3>
            <ul className='advantageList'>
              <li>
                <p>Только качественная продукция из первичного сырья;</p>
              </li>
              <li>
                <p>Круглогодичное производство в г. Якутске;</p>
              </li>
              <li>
                <p>Возможность изготовления труб в бухтах до 300 м.;</p>
              </li>
              <li>
                <p>Доставка продукции;</p>
              </li>
              <li>
                <p>Знание особенностей логистики Республики Саха (Якутия);</p>
              </li>
              <li>
                <p>10-летний опыт работы;</p>
              </li>
              <li>
                <p>Собственный автопарк грузовой и спецтехники;</p>
              </li>
              <li>
                <p>Разработка проектов водопроводов для участия в ППМИ;</p>
              </li>
              <li>
                <p>Наличие собственной опытной бригады монтажников.</p>
              </li>
            </ul>
          </div>
          <div style={{ flex: '1 1 calc((100% - 100px) / 2)' }}>
            <img style={{ width: '100%' }} alt='' src={require('../img/advantages1.png')} />
            <img style={{ width: '100%' }} alt='' src={require('../img/advantages2.png')} />
          </div>
        </section>
        <section className='wrap' style={{ gap: 50 }}>
          <img alt='' src={require('../img/bochka.png')} className='formImg' style={{ flex: '1 1 calc((100% - 100px) / 2)', objectFit: 'contain' }} />
          <form onSubmit={emailSend} style={{ flex: '1 1 calc((100% - 100px) / 2)', minWidth: 350 }} className='request'>
            <h1 style={{ marginBottom: 30 }}>Обратная связь</h1>
            <div style={{ marginBottom: 15 }} className='flex sb ai_fs'>
              <div className='inputs'>
                <label>
                  <h4 style={{ marginBottom: 10 }}>Ваше Имя</h4>
                  <input name='name' />
                </label>
                <label>
                  <h4 style={{ marginBottom: 10 }}>Ваш телефон</h4>
                  <MaskedInput mask='+7(000)000-00-00' name='tel' />
                </label>
                <label>
                  <h4 style={{ marginBottom: 10 }}>Эл. почта</h4>
                  <input name='email' type='email' />
                </label>
                <p style={{ marginBottom: 15, lineHeight: 1.5 }}>
                  Оставьте заявку на сайте или звоните нам{' '}
                  <a href='tel:+79142747703' className='phone'>
                    +7 (914) 274-77-03
                  </a>{' '}
                  <br /> Наши менеджеры подберут для вас лучшее решение
                </p>
                <label className='message'>
                  <h3 style={{ marginBottom: 10 }}>Ваш вопрос</h3>
                  <textarea name='question'></textarea>
                </label>
              </div>
            </div>
            <div className='flex sb ai_fs'>
              <label style={{ gap: 10 }} className='flex'>
                <Checkbox onChange={(e) => setAgree(e.target.checked)} />
                <p>
                  Я согласен(а) на <Link to='/'>обработку персональных данных</Link>
                </p>
              </label>
              <button type='submit'>{loadingEmail ? 'Ожидание...' : 'Отправить'}</button>
            </div>
          </form>
        </section>
        <div style={{ marginTop: 100 }}>
          <h1 style={{ textAlign: 'center' }}>Главные события за последний месяц</h1>
          <div className='homecards v2 flex wrap'>
            {loading ? (
              <Loader />
            ) : (
              data?.findManyNews.map((item) => (
                <Link key={item.id} to={`/events/${item.id}`}>
                  <img alt='' src={item.image} />
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </Link>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
