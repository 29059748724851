import { create } from 'zustand'

const items = JSON.parse(localStorage.getItem('items'))
const useStore = create((set) => ({
  count: items ? items.length : 0,
  inc: () => set((state) => ({ count: state.count + 1 })),
  dec: () => set((state) => ({ count: state.count - 1 })),
  clear: () => set(() => ({ count: 0 })),
}))

export default useStore
