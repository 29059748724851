import { useMutation } from '@apollo/client'
import { message } from 'antd'
import { Link } from 'react-router-dom'
import { SEND_EMAIL } from '../gqls/email/mutation'

const Footer = () => {
  const [sendEmail, { loading }] = useMutation(SEND_EMAIL, {
    onCompleted: () =>
      message.open({
        type: 'success',
        content: 'Сообщение отправлено! Вам скоро ответят.',
      }),
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })
  const emailSend = (e) => {
    e.preventDefault()
    if (!loading && e.target.contact.value.length > 5) {
      sendEmail({
        variables: {
          data: {
            title: 'Запрос на обратную связь',
            description: `Контакт: ${e.target.contact.value}\n\nЗапрос был сделан в "подвале" сайта.`,
          },
        },
      })
      e.target.reset()
    }
  }
  return (
    <footer>
      <div className='content flex sb'>
        <div style={{ flexGrow: 1 }}>
          <img src={require('../img/logo1.png')} alt='logo' />
        </div>
        <div style={{ flexGrow: 1, flexWrap: 'wrap' }} className='flex ai_fs sb'>
          <div>
            <h4>Навигация</h4>
            <ul>
              <li>
                <Link to='/catalog'>Маркет</Link>
              </li>
              <li>
                <Link to='/egoplast'>О компании</Link>
              </li>
              <li>
                <Link to='/egoplast'>Производство</Link>
              </li>
              <li>
                <Link to='/contacts'>Контакты</Link>
              </li>
            </ul>
          </div>
          <div>
            <h4>Контакты</h4>
            <ul>
              <li>
                <Link to='https://wa.me/79142730700' target='_blank'>
                  Whatsapp
                </Link>
              </li>
              <li>
                <Link to='https://t.me/technoplastic_ykt' target='_blank'>
                  Telegram
                </Link>
              </li>
              <li>
                <Link to='https://instagram.com/egoplast_ykt' target='_blank'>
                  Instagram
                </Link>
              </li>
              <li>
                <p>г. Якутск, ул. Дзержинского 76</p>
              </li>
              <li>
                <p>+7 (914) 274-77-03</p>
              </li>
            </ul>
          </div>
        </div>
        <div className='footerform'>
          <div>
            <div>
              <h4>Cвяжитесь с нами</h4>
              <form onSubmit={emailSend}>
                <input name='contact' placeholder='Номер телефона или почта' />
                <button type='submit'>{loading ? 'Ожидание...' : 'Отправить'}</button>
              </form>
            </div>
            <img alt='' className='logold' src={require('../img/logold.jpg')} />
            <p>© 2013 - 2024 ООО “Эгопласт”. Все права защищены</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
