import { useState } from 'react'
import { Carousel, Image } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import useStore from '../../../utils/store'
import Urns from './Urns'
const data = [
  {
    id: 'kolymbaki',
    type: 'kolymbaki',
    title: 'Колымбаки',
    images: [require('../../../img/catalog/other/Kolymbaki/1.jpg'), require('../../../img/catalog/other/Kolymbaki/2.jpg')],
  },
  {
    id: 'sleighВrag',
    type: 'sleighВrag',
    title: 'Cани-волокуши',
    images: [require('../../../img/catalog/other/sleighВrag/1.jpg'), require('../../../img/catalog/other/sleighВrag/2.jpg')],
  },
  {
    id: 'draggingStretchers',
    type: 'draggingStretchers',
    title: 'Носилки-волокуши',
    images: [require('../../../img/catalog/other/draggingStretchers/1.jpg'), require('../../../img/catalog/other/draggingStretchers/2.jpg')],
  },
  {
    id: 'greaseTraps',
    type: 'greaseTraps',
    title: 'Жироуловители',
    images: [require('../../../img/catalog/other/greaseTraps/1.png'), require('../../../img/catalog/other/greaseTraps/2.jpg')],
  },
  {
    id: 'shovels',
    type: 'shovels',
    title: 'Лопаты',
    images: [require('../../../img/catalog/other/shovels/1.jpg'), require('../../../img/catalog/other/shovels/2.jpg')],
  },
]

const Other = () => {
  const navigate = useNavigate()
  const [choice, setChoice] = useState(data[0])
  const [isUrns, setIsUrns] = useState(false)
  const { inc } = useStore()

  const getState = (item) => {
    const cartItems = JSON.parse(localStorage.getItem('items')) || []
    return !cartItems.some((cartItem) => cartItem.id === item.id)
  }
  const [state, setState] = useState(getState(choice))
  const radioClick = (item) => {
    setIsUrns(false)
    setState(getState(item))
    setChoice(item)
  }
  const addItemToCart = () => {
    const cartItems = JSON.parse(localStorage.getItem('items')) || []
    const newItem = { ...choice, spec: true }
    const updatedCartItems = [...cartItems, newItem]
    localStorage.setItem('items', JSON.stringify(updatedCartItems))
    setState(false)
    inc()
  }
  return (
    <div className='content'>
      <div className='yetfull flex' style={{ display: 'block' }}>
        <div>
          <Link to='/catalog'>Каталог</Link> — Колымбаки, волокуши и др. изделия из полиэтилена и пропилена
        </div>
        <div className='product flex wrap ai_fs'>
          <Image.PreviewGroup>
            {!isUrns ? (
              <Carousel infinite={false}>
                {choice.images.map((item, index) => (
                  <Image key={index} src={item} />
                ))}
              </Carousel>
            ) : (
              <Carousel infinite={false}>
                <Image src={require('../../../img/catalog/urns/1.png')} />
                <Image src={require('../../../img/catalog/urns/2.png')} />
                <Image src={require('../../../img/catalog/urns/3.png')} />
                <Image src={require('../../../img/catalog/urns/4.png')} />
                <Image src={require('../../../img/catalog/urns/5.png')} />
              </Carousel>
            )}
          </Image.PreviewGroup>
          <div className='info'>
            <div className='flex wrap' style={{ gap: 14 }}>
              {data.map((item, index) => (
                <div key={item.id}>
                  <input defaultChecked={index === 0} id={item.id} onChange={() => radioClick(item)} type='radio' name='container-type' />
                  <label htmlFor={item.id}>
                    <h4>{item.title}</h4>
                  </label>
                </div>
              ))}
              <div>
                <input onChange={() => setIsUrns(true)} id='urns' type='radio' name='container-type' />
                <label htmlFor='urns'>
                  <h4>Урны</h4>
                </label>
              </div>
            </div>
            {!isUrns ? (
              <>
                <p>Описание</p>
                <div style={{ marginBottom: 20 }}>
                  • любые размеры; <br />• индивидуальный заказ;
                  <br /> • любая форма по желанию клиента;
                </div>
                <div className='flex wrap'>
                  <button className={!state ? 'tobasket' : ''} onClick={() => (state ? addItemToCart() : navigate('/catalog/basket'))}>
                    В корзин{state ? 'у' : 'е'}
                  </button>
                  <p style={{ lineHeight: '130%', opacity: 1, fontWeight: '300', margin: 0 }}>
                    Связаться с менеджером <br /> по номеру{' '}
                    <a className='phone' href='tel:+79142730700'>
                      +7 (914) 273-07-00
                    </a>
                  </p>
                </div>
              </>
            ) : (
              <Urns />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Other
