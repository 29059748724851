import { useQuery } from '@apollo/client'
import { Carousel, Image } from 'antd'
import { FIND_MANY_RUNNERS } from '../../../gqls/catalog/query'
import Loader from '../../../components/Loader'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useStore from '../../../utils/store'

const Skids = () => {
  const navigate = useNavigate()
  const [choice, setChoice] = useState({})
  const [state, setState] = useState(false)
  const [type, setType] = useState('')
  const { data, loading } = useQuery(FIND_MANY_RUNNERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setType(data.findManyRunners[0].thickness)
      radioClick(data.findManyRunners[0])
    },
  })
  const getState = (choice) => {
    let items = JSON.parse(localStorage.getItem('items'))
    if (items && items.find((i) => i.id === choice.id)) return false
    return true
  }
  const { inc } = useStore()
  const getType = (type) => {
    setType(type)
    radioClick(data.findManyRunners.filter((item) => item.thickness === type)[0])
  }
  const radioClick = (item) => {
    setState(getState(item))
    setChoice(item)
  }
  const onClick = () => {
    let items = JSON.parse(localStorage.getItem('items'))
    let object = { ...choice }
    object.spec = true
    object.type = 'skid'
    object.title = `Полозья ${object.thickness}мм ${object.length}л`
    if (items) items.push(object)
    if (!items) items = [object]
    localStorage.setItem('items', JSON.stringify(items))
    setState(false)
    inc()
  }
  return (
    <div className='content'>
      <div className='yetfull flex' style={{ display: 'block' }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div>
              <Link to='/catalog'>Каталог</Link> — Полозья
            </div>
            <div className='product flex wrap ai_fs'>
              <Image.PreviewGroup>
                <Carousel infinite={false} autoplay>
                  <Image src={require('../../../img/catalog/skids/4.png')} />
                  <Image src={require('../../../img/catalog/skids/2.png')} />
                  <Image src={require('../../../img/catalog/skids/1.png')} />
                  <Image src={require('../../../img/catalog/skids/3.png')} />
                </Carousel>
              </Image.PreviewGroup>
              <div className='info'>
                <h1>Полозья</h1>
                <p>Выберите размер (толщина в мм)</p>
                <div style={{ gap: 7.5 }} className='flex wrap'>
                  {[...new Set(data.findManyRunners.map((item) => item.thickness))].map((item, index) => (
                    <div key={item}>
                      <input defaultChecked={index === 0} id={index} type='radio' onChange={() => getType(item)} name='thickness' />
                      <label htmlFor={index}>{item}</label>
                    </div>
                  ))}
                </div>
                <p>Выберите длину</p>
                <div style={{ gap: 7.5 }} className='flex wrap'>
                  {data?.findManyRunners
                    .filter((item) => item.thickness === type)
                    .map((item, index) => (
                      <div key={item.id}>
                        <input onChange={() => radioClick(item)} defaultChecked={index === 0} id={item.id} type='radio' name='length' />
                        <label htmlFor={item.id}>Длина {item.length} м</label>
                      </div>
                    ))}
                </div>
                <h1 style={{ marginTop: 20, marginBottom: 20 }}>{choice?.cost?.toLocaleString('ru')} РУБ</h1>
                <div className='flex wrap'>
                  <button className={!state ? 'tobasket' : ''} onClick={() => (state ? onClick() : navigate('/catalog/basket'))}>
                    В корзин{state ? 'у' : 'е'}
                  </button>
                  <p style={{ lineHeight: '130%', opacity: 1, fontWeight: '300', margin: 0 }}>
                    Связаться с менеджером <br /> по номеру{' '}
                    <a className='phone' href='tel:+79142730700'>
                      +7 (914) 273-07-00
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Skids
