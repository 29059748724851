import { useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import { FIND_ONE_SUB_CATEGORY } from '../../../gqls/catalog/query'
import Loader from '../../../components/Loader'
const SubCategory = () => {
  const { id } = useParams()
  const { data, loading } = useQuery(FIND_ONE_SUB_CATEGORY, { fetchPolicy: 'network-only', variables: { where: { id } } })
  return (
    <div className='content'>
      <div className='yetfull' style={{ display: 'block' }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className='heading'>
              <Link to='/catalog/'>Каталог</Link> — <Link to='/catalog/fittings'>Комплектующие и фитинги</Link> — {data?.findUniqueSubCategory.title}
            </div>
            <div style={{ marginTop: 20 }} className='flex wrap ai_n'>
              {data?.findUniqueSubCategory?.subSubCategory.map((item) => (
                <div key={item.id} className='category'>
                  <img src={item.image} alt='' />
                  <h3>{item.title}</h3>
                  <Link to={`/catalog/fittings/product/${item.id}`}>Перейти</Link>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default SubCategory
