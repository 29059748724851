import { Carousel, Image } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import useStore from '../../../utils/store'
import { useState } from 'react'
const data = [
  { id: 'tugun', type: 'boat', title: 'Тугун - 370', cost: 170000, defaultChecked: true, img: require('../../../img/catalog/boats/tugun370.jpg') },
  { id: 'tugun400', type: 'boat', title: 'Тугун - 400', cost: 185000, img: require('../../../img/catalog/boats/tugun400.png') },
  { id: 'sever', type: 'boat', title: 'Север - 420', cost: 320000, img: require('../../../img/catalog/boats/sever420.png') },
  { id: 'sever1', type: 'boat', title: 'Север - 520', cost: 370000, img: require('../../../img/catalog/boats/sever520.png') },
  { id: 'krim', type: 'boat', title: 'Крым-475', cost: 305000, img: require('../../../img/catalog/boats/krim.png') },
  { id: 'progress', type: 'boat', title: 'Прогресс-465', cost: 370000, img: require('../../../img/catalog/boats/progress.png') },
  { id: 'limba', type: 'boat', title: 'Лимба - 500', cost: 320000, img: require('../../../img/catalog/boats/limba500.png') },
  { id: 'aero', type: 'boat', title: 'Аэролодка-320', cost: 260000, img: require('../../../img/catalog/boats/2.jpg') },
  {
    id: 'aeroWithoutMotor',
    type: 'boat',
    title: 'Аэролодка без мотора',
    cost: 115000,
    img: require('../../../img/catalog/boats/photo_2024-04-26_10-50-19.jpg'),
  },
  { id: 'kazanka', type: 'boat', title: 'Казанка М-465 с булями', cost: 240000, img: require('../../../img/catalog/boats/kazanka.png') },
  {
    id: 'imperator',
    type: 'boat',
    title: 'Император-8.5 с защитой винта',
    cost: 263000,
    img: require('../../../img/catalog/boats/imperator.png'),
  },
]

const Boats = () => {
  const navigate = useNavigate()
  const [boat, setBoat] = useState(data[0])
  const getState = (boat) => {
    let items = JSON.parse(localStorage.getItem('items'))
    if (items && items.find((i) => i.id === boat.id)) return false
    return true
  }
  const [state, setState] = useState(getState(boat))
  const { inc } = useStore()
  const radioClick = (item) => {
    setState(getState(item))
    setBoat(item)
  }
  const onClick = () => {
    let items = JSON.parse(localStorage.getItem('items'))
    let object = { ...boat }
    object.spec = true
    object.title = 'Лодка ' + boat.title
    if (items) items.push(object)
    if (!items) items = [object]
    localStorage.setItem('items', JSON.stringify(items))
    setState(false)
    inc()
  }
  return (
    <div className='content'>
      <div className='yetfull flex' style={{ display: 'block' }}>
        <div>
          <Link to='/catalog'>Каталог</Link> — Лодки
        </div>
        <div className='product flex wrap ai_fs'>
          <Image.PreviewGroup>
            <Carousel infinite={false} autoplay>
              <Image src={boat.img} />
              {/* <Image src={require('../../../img/catalog/boats/1.png')} />
              <Image src={require('../../../img/catalog/boats/2.jpg')} />
              <Image src={require('../../../img/catalog/boats/3.jpg')} />
              <Image src={require('../../../img/catalog/boats/4.jpg')} />
              <Image src={require('../../../img/catalog/boats/5.jpg')} />
              <Image src={require('../../../img/catalog/boats/6.png')} />
              <Image src={require('../../../img/catalog/boats/7.png')} />
              <Image src={require('../../../img/catalog/boats/8.png')} /> */}
            </Carousel>
          </Image.PreviewGroup>
          <div className='info'>
            <h1>Лодки</h1>
            <p>Описание</p>
            <div style={{ marginBottom: 20 }}>
              Изготовленые из листов полиэтилена (ПНД), который обладает исключительной прочностью и морозостойкостью, позволяющие эксплуатировать их
              в самых жестких северных условиях.
            </div>
            <div style={{ gap: 7.5 }} className='flex wrap'>
              {data.map((item, index) => (
                <div key={item.id} className='radio2'>
                  <input
                    defaultChecked={item.defaultChecked}
                    id={index}
                    onChange={() => radioClick(item)}
                    type='radio'
                    value={`${item.title}, ${item.cost.toLocaleString('ru')}`}
                    name='volume'
                  />
                  <label htmlFor={index}>
                    <span>{item.title}</span>
                  </label>
                </div>
              ))}
            </div>
            <h1 style={{ marginTop: 20, marginBottom: 20 }}>{boat.cost.toLocaleString('ru')} РУБ</h1>
            <div style={{ marginTop: 20 }} className='flex wrap'>
              <button className={!state ? 'tobasket' : ''} onClick={() => (state ? onClick() : navigate('/catalog/basket'))}>
                В корзин{state ? 'у' : 'е'}
              </button>
              <p style={{ lineHeight: '130%', opacity: 1, fontWeight: '300', margin: 0 }}>
                Также вы можете дополнительно заказать комплектующие по своим желаниям. Для этого свяжитесь с менеджером по номеру{' '}
                <a className='phone' href='tel:+79142730700'>
                  +7 (914) 273-07-00
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Boats
