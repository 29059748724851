import { useQuery } from '@apollo/client'
import { Carousel, Image } from 'antd'
import { FIND_MANY_OVAL_CONTAINERS } from '../../gqls/catalog/query'
import Loader from '../Loader'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useStore from '../../utils/store'

const getState = (choice) => {
  let items = JSON.parse(localStorage.getItem('items'))
  if (items && items.find((i) => i.id === choice.id)) return false
  return true
}

const ContainersOval = ({ changeType }) => {
  const navigate = useNavigate()
  const [choice, setChoice] = useState({})
  const [state, setState] = useState(false)
  const [type, setType] = useState('')
  const { data, loading } = useQuery(FIND_MANY_OVAL_CONTAINERS, {
    onCompleted: (data) => {
      const sortedWidth = [...new Set(data?.findManyOvalContainers.map((item) => item.width))].sort((a, b) => a - b)[0]
      setType(sortedWidth)
      radioClick(data?.findManyOvalContainers.filter((item) => item.width === sortedWidth).sort((a, b) => a.weight - b.weight)[0])
    },
  })

  const { inc } = useStore()
  const getType = (type) => {
    setType(type)
    radioClick(data.findManyOvalContainers.filter((item) => item.width === type).sort((a, b) => a.weight - b.weight)[0])
  }
  const radioClick = (item) => {
    setState(getState(item))
    setChoice(item)
  }
  const onClick = () => {
    let items = JSON.parse(localStorage.getItem('items'))
    let object = { ...choice }
    object.spec = true
    object.type = 'oval'
    object.title = `Овальная емкость ${object.width}л | ${object.sizes}см`
    if (items) items.push(object)
    if (!items) items = [object]
    localStorage.setItem('items', JSON.stringify(items))
    setState(false)
    inc()
  }
  const sortedWidth = [...new Set(data?.findManyOvalContainers.map((item) => item.width))].sort((a, b) => a - b)
  const sortedWeight = data?.findManyOvalContainers.filter((item) => item.width === type).sort((a, b) => a.weight - b.weight)
  return loading ? (
    <Loader />
  ) : (
    <div className='product flex wrap ai_fs'>
      <Image.PreviewGroup>
        <Carousel infinite={false} autoplay>
          <Image src={require('../../img/catalog/ContainersOval/1.png')} />
          <Image src={require('../../img/catalog/ContainersOval/2.png')} />
          <Image src={require('../../img/catalog/ContainersOval/3.png')} />
          <Image src={require('../../img/catalog/ContainersOval/4.png')} />
          <Image src={require('../../img/catalog/ContainersOval/5.png')} />
          <Image src={require('../../img/catalog/ContainersOval/6.png')} />
          <Image src={require('../../img/catalog/ContainersOval/7.png')} />
          <Image src={require('../../img/catalog/ContainersOval/8.png')} />
          <Image src={require('../../img/catalog/ContainersOval/9.png')} />
        </Carousel>
      </Image.PreviewGroup>
      <div className='info'>
        <div className='flex wrap' style={{ gap: 14 }}>
          <div>
            <input onChange={() => changeType(true)} id='round' type='radio' name='container-type' />
            <label htmlFor='round'>
              <h4>Круглые емкости</h4>
            </label>
          </div>
          <div>
            <input defaultChecked onChange={() => changeType(false)} id='oval' type='radio' name='container-type' />
            <label htmlFor='oval'>
              <h4>Овальные емкости</h4>
            </label>
          </div>
        </div>
        <div>
          <p>Выберите ширину</p>
          <div style={{ gap: 7.5 }} className='flex wrap'>
            {sortedWidth.map((item, index) => (
              <div key={item}>
                <input defaultChecked={index === 0} id={index} type='radio' onChange={() => getType(item)} name='width' />
                <label htmlFor={index}>{item} см</label>
              </div>
            ))}
          </div>
        </div>
        <p>Выберите объем (Литры | Д*Ш*В)</p>
        <div style={{ gap: 7.5 }} className='flex wrap'>
          {sortedWeight.map((item, index) => (
            <div key={item.id}>
              <input onChange={() => radioClick(item)} defaultChecked={index === 0} id={item.id} type='radio' name='volume' />
              <label htmlFor={item.id}>{item.weight + 'л | ' + item.sizes}см</label>
            </div>
          ))}
        </div>
        <h1 style={{ marginTop: 20, marginBottom: 20 }}>{choice?.cost?.toLocaleString('ru')} РУБ</h1>
        <div className='flex wrap'>
          <button className={!state ? 'tobasket' : ''} onClick={() => (state ? onClick() : navigate('/catalog/basket'))}>
            В корзин{state ? 'у' : 'е'}
          </button>
          <p style={{ lineHeight: '130%', opacity: 1, fontWeight: '300', margin: 0 }}>
            Для заказа емкостей нестандартных форм обратитесь <br /> к менеджеру по номеру{' '}
            <a className='phone' href='tel:+79142730700'>
              +7 (914) 273-07-00
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  )
}

export default ContainersOval
