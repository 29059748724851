import { gql } from '@apollo/client'

export const FIND_MANY_SUB_CATEGORY = gql`
  query {
    findManySubCategory {
      id
      title
      image
      subSubCategory {
        id
      }
    }
  }
`
export const FIND_ONE_SUB_CATEGORY = gql`
  query ($where: SubCategoryWhereUniqueInput!) {
    findUniqueSubCategory(where: $where) {
      id
      title
      subSubCategory {
        id
        title
        image
      }
    }
  }
`
export const FIND_ONE_SUB_SUB_CATEGORY = gql`
  query ($where: SubSubCategoryWhereUniqueInput!) {
    findUniqueSubSubCategory(where: $where) {
      id
      title
      product {
        id
        title
        image
        cost
      }
      subCategory {
        id
        title
      }
    }
  }
`
export const FIND_MANY_ROUND_CONTAINERS = gql`
  query {
    findManyRoundContainers {
      id
      width
      cost
      sizes
    }
  }
`
export const FIND_MANY_SEPTIC = gql`
  query {
    findManySeptic {
      id
      volume
      sizes
      cost
    }
  }
`
export const FIND_MANY_POOLS = gql`
  query {
    findManyPools {
      id
      volume
      sizes
      cost
    }
  }
`
export const FIND_MANY_URNS = gql`
  query {
    findManyUrns {
      id
      volume
      sizes
      cost
    }
  }
`
export const FIND_MANY_WATER_CONTAINERS = gql`
  query {
    findManyWaterContainers {
      id
      isSummers
      volume
      sizes
      cost
    }
  }
`
export const FIND_MANY_OVAL_CONTAINERS = gql`
  query {
    findManyOvalContainers {
      id
      cost
      width
      weight
      sizes
    }
  }
`
export const FIND_MANY_RUNNERS = gql`
  query {
    findManyRunners {
      id
      cost
      thickness
      length
    }
  }
`
