const Total = ({ items }) => {
  let total = 0
  for (let item of items) {
    if (item.cost) {
      total += item.cost * item.count
    }
  }
  return total.toLocaleString('ru')
}

export default Total
