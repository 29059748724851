import months from '../utils/months'
const TodayDate = (today, noTime = false) => {
  const date = new Date(today)
  const yyyy = date.getFullYear()
  let mm = months[date.getMonth()]
  let dd = date.getDate()
  let hh = date.getHours()
  let m = date.getMinutes()
  let time = ''

  if (dd < 10) dd = '0' + dd
  if (hh < 10) hh = '0' + hh
  if (m < 10) m = '0' + m
  if (!noTime) time = hh + ':' + m
  const nowDate = dd + ' ' + mm + ' ' + yyyy + ' ' + time
  return nowDate
}
export default TodayDate
