import { Checkbox, message } from 'antd'
import { Link } from 'react-router-dom'
import card1 from '../../img/catalog/main/catalogcard1.png'
import card2 from '../../img/catalog/main/catalogcard2.png'
import card3 from '../../img/catalog/main/catalogcard3.png'
import card4 from '../../img/catalog/main/catalogcard4.png'
import card5 from '../../img/catalog/main/catalogcard5.png'
import card6 from '../../img/catalog/main/catalogcard6.png'
import card7 from '../../img/catalog/main/catalogcard7.png'
import card8 from '../../img/catalog/main/catalogcard10.png'
import card9 from '../../img/catalog/main/catalogcard9.png'
import card11 from '../../img/catalog/main/catalogcard11.png'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { SEND_EMAIL } from '../../gqls/email/mutation'
import { MaskedInput } from 'antd-mask-input'

const Index = () => {
  const [agree, setAgree] = useState(false)

  const [sendEmail, { loading }] = useMutation(SEND_EMAIL, {
    onCompleted: () => message.open({ type: 'success', content: 'Сообщение отправлено! Вам скоро ответят.' }),
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const emailSend = (e) => {
    e.preventDefault()
    const { name, tel, email, form, note } = e.target
    if (!agree) {
      message.open({ type: 'warning', content: 'Необходимо согласие на обработку данных.' })
      return null
    }
    if (!loading && name.value && tel.value && email.value && note.value) {
      sendEmail({
        variables: {
          data: {
            title: 'Запрос изделия под себя',
            description: `Имя: ${name.value}\nТелефон: ${tel.value}\nПочта: ${email.value}\nФорма: ${form.value}.\nПримечания:\n"${note.value}".\n\nЗапрос был сделан в окне "Катаог".`,
          },
        },
      })
      tel.value = ''
      e.target.reset()
    } else {
      message.open({ type: 'warning', content: 'Заполните все поля.' })
    }
  }
  const Arrow = () => <img alt='' width={25} src={require('../../img/arrow1.png')} />
  return (
    <div className='content'>
      <section className='yetfull'>
        <div>
          <h3 style={{ fontFamily: 'Gilroy', fontWeight: 100 }}>Каталог</h3>
          <div className='homecards catalog flex wrap'>
            <Link to='/catalog/pipes' className='lilcard big' style={{ backgroundImage: `url(${card1})` }}>
              <h2>ПОЛИЭТИЛЕНОВЫЕ ТРУБЫ</h2>
              <Arrow />
            </Link>
            <Link to='/catalog/fittings' className='lilcard' style={{ backgroundImage: `url(${card2})` }}>
              <h2>КОМПЛЕКТУЮЩИЕ И ФИТИНГИ</h2>
              <Arrow />
            </Link>
            <Link to='/catalog/round-and-oval-containers' className='lilcard' style={{ backgroundImage: `url(${card3})` }}>
              <h2>КРУГЛЫЕ И ОВАЛЬНЫЕ ЕМКОСТИ</h2>
              <Arrow />
            </Link>
            <Link to='/catalog/septic' className='lilcard' style={{ backgroundImage: `url(${card9})` }}>
              <h2>СЕПТИКИ</h2>
              <Arrow />
            </Link>
            <Link to='/catalog/water-carriers' className='lilcard' style={{ backgroundImage: `url(${card4})` }}>
              <h2>ВОДОВОЗКИ</h2>
              <Arrow />
            </Link>
            <Link to='/catalog/skids' className='lilcard big' style={{ backgroundImage: `url(${card5})` }}>
              <h2>ПОЛОЗЬЯ</h2>
              <Arrow />
            </Link>
            <Link to='/catalog/boats' className='lilcard' style={{ backgroundImage: `url(${card8})` }}>
              <h2>ЛОДКИ</h2>
              <Arrow />
            </Link>
            <Link to='/catalog/pools' className='lilcard' style={{ backgroundImage: `url(${card6})` }}>
              <h2>БАССЕЙНЫ</h2>
              <Arrow />
            </Link>
            <Link to='/catalog/other' className='lilcard' style={{ backgroundImage: `url(${card7})` }}>
              <h2>Колымбаки, волокуши и др. изделия</h2>
              <Arrow />
            </Link>
            <Link to='/catalog/pontoons' className='lilcard' style={{ backgroundImage: `url(${card11})` }}>
              <h2>Дебаркадеры, понтоны</h2>
              <Arrow />
            </Link>
          </div>
        </div>
      </section>
      <section>
        <div className='request'>
          <h3 style={{ fontFamily: 'Gilroy', fontWeight: 100 }}>Изделие под себя</h3>
          <div className='flex sb  ai_fe' style={{ gap: 100 }}>
            <h1>Заполните бланк вашего заказа</h1>
            <p style={{ fontSize: 20, flexGrow: 1.5 }}>
              Укажите для чего предназначено ваше изделие под заказ. Размеры, длина, ширина высота. Нужно учесть ограничение по дверным проемам, если
              таковые есть. А так же указать эксплуатацию.
            </p>
          </div>
          <form id='form' onSubmit={emailSend} style={{ marginBottom: 5, gap: 100 }} className='flex requestform sb wrap ai_fs'>
            <div className='inputs'>
              <label>
                <h5>Ваше Имя</h5>
                <input name='name' />
              </label>
              <label>
                <h5>Ваш телефон</h5>
                <MaskedInput mask='+7(000)000-00-00' name='tel' />
              </label>
              <label>
                <h5>Эл. почта</h5>
                <input name='email' type='email' />
              </label>
              <label>
                <h5>Форма: овал, прямоугольник, круг (опционально)</h5>
                <input name='form' />
              </label>
            </div>
            <div className='message'>
              <label>
                <h5>Примечание</h5>
                <textarea name='note' style={{ height: 100 }}></textarea>
              </label>
            </div>
          </form>
          <div className='flex wrap sb ai_fs'>
            <label style={{ gap: 10 }} className='flex'>
              <Checkbox onChange={(e) => setAgree(e.target.checked)} />
              <p>
                Я согласен(а) на <Link to='/'>обработку персональных данных</Link>
              </p>
            </label>
            <button form='form' type='submit'>
              {loading ? 'Ожидание...' : 'Отправить'}
            </button>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Index
