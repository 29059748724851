import { useQuery } from '@apollo/client'
import { Carousel, Image } from 'antd'
import { FIND_MANY_ROUND_CONTAINERS } from '../../gqls/catalog/query'
import Loader from '../../components/Loader'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useStore from '../../utils/store'

const getState = (choice) => {
  let items = JSON.parse(localStorage.getItem('items'))
  if (items && items.find((i) => i.id === choice.id)) return false
  return true
}

const ContainersRound = ({ changeType }) => {
  const navigate = useNavigate()

  //овальные емкости
  const { data, loading } = useQuery(FIND_MANY_ROUND_CONTAINERS, {
    onCompleted: (data) => radioClick([...data?.findManyRoundContainers].sort((a, b) => a.width - b.width)[0]),
  })

  const [choice, setChoice] = useState({})

  const [state, setState] = useState(getState(choice))
  const { inc } = useStore()
  const radioClick = (item) => {
    setState(getState(item))
    setChoice(item)
  }
  const onClick = () => {
    let items = JSON.parse(localStorage.getItem('items'))
    let object = { ...choice }
    object.spec = true
    object.type = 'round'
    object.title = `Круглая емкость ${object.width}л | ${object.sizes}см`
    if (items) items.push(object)
    if (!items) items = [object]
    localStorage.setItem('items', JSON.stringify(items))
    setState(false)
    inc()
  }

  return loading ? (
    <Loader />
  ) : (
    <div className='product flex wrap ai_fs'>
      <Carousel infinite={false} autoplay>
        <Image src={require('../../img/catalog/round.jpg')} />
      </Carousel>
      <div className='info'>
        <div className='flex wrap' style={{ gap: 14 }}>
          <div>
            <input defaultChecked onChange={() => changeType(true)} id='round' type='radio' name='container-type' />
            <label htmlFor='round'>
              <h4>Круглые емкости</h4>
            </label>
          </div>
          <div>
            <input onChange={() => changeType(false)} id='oval' type='radio' name='container-type' />
            <label htmlFor='oval'>
              <h4>Овальные емкости</h4>
            </label>
          </div>
        </div>
        <p>Выберите объем</p>
        <div style={{ gap: 7.5 }} className='flex wrap'>
          {[...data?.findManyRoundContainers]
            .sort((a, b) => a.width - b.width)
            .map((item, index) => (
              <div key={item.id}>
                <input defaultChecked={index === 0} onChange={() => radioClick(item)} id={index} type='radio' name='volume' />
                <label htmlFor={index}>{item.width + 'л | ' + item.sizes}см</label>
              </div>
            ))}
        </div>
        <h1 style={{ marginTop: 20, marginBottom: 20 }}>{choice?.cost?.toLocaleString('ru')} РУБ</h1>
        <div className='flex wrap'>
          <button className={!state ? 'tobasket' : ''} onClick={() => (state ? onClick() : navigate('/catalog/basket'))}>
            В корзин{state ? 'у' : 'е'}
          </button>
          <p style={{ lineHeight: '130%', opacity: 1, fontWeight: '300', margin: 0 }}>
            Для заказа емкостей нестандартных форм обратитесь <br /> к менеджеру по номеру{' '}
            <a className='phone' href='tel:+79142730700'>
              +7 (914) 273-07-00
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  )
}

export default ContainersRound
