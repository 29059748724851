import { useState } from 'react'
import ContainersOval from '../../../components/catalog/ContainersOval'
import ContainersRound from '../../../components/catalog/ContainersRound'
import { Link } from 'react-router-dom'

const ContainersRoundAndOval = () => {
  const [isRound, setIsRound] = useState(true)
  return (
    <div className='content'>
      <div className='yetfull flex' style={{ display: 'block' }}>
        <div>
          <Link to='/catalog'>Каталог</Link> — Круглые и овальные емкости
        </div>
        {isRound ? <ContainersRound changeType={setIsRound} /> : <ContainersOval changeType={setIsRound} />}
      </div>
    </div>
  )
}

export default ContainersRoundAndOval
