import { useState } from 'react'
import useStore from '../../utils/store'

const specImage = (item) => {
  switch (item.type) {
    case 'pipes':
      return require('../../img/catalog/pipes.png')
    case 'boat':
      return require('../../img/catalog/main/basket/boat.png')
    case 'round':
      return require('../../img/catalog/main/basket/round.png')
    case 'septic':
      return require('../../img/catalog/septic/1.png')
    case 'pool':
      return require('../../img/catalog/main/basket/pool.png')
    case 'urn':
      return require('../../img/catalog/main/basket/urn.png')
    case 'waterCarrier':
      return require('../../img/catalog/main/basket/waterCarrier.png')
    case 'oval':
      return require('../../img/catalog/main/basket/oval.png')
    case 'skid':
      return require('../../img/catalog/main/basket/skid.png')
    case 'pontoon':
      return require('../../img/catalog/main/basket/pontoon.png')
    case 'greaseTraps':
      return require('../../img/catalog/other/greaseTraps/1.png')
    case 'shovels':
      return require('../../img/catalog/main/basket/shovel.png')
    case 'draggingStretchers':
      return require('../../img/catalog/other/draggingStretchers/1.jpg')
    case 'sleighВrag':
      return require('../../img/catalog/main/basket/sleighВrag.png')
    case 'kolymbaki':
      return require('../../img/catalog/main/basket/kolymbaki.png')
    default:
      return require('../../img/catalog/pipes.png')
  }
}

const BasketItem = ({ item, setData, index, data }) => {
  const [number, setnumber] = useState(1)
  const { dec } = useStore()

  const onChange = (count) => {
    if (index >= 0) {
      const arr = [...data]
      arr[index] = { ...item, count }
      setData(arr)
    }
    setnumber(count)
  }
  const removeItem = () => {
    const arr = [...data]
    arr.splice(index, 1)
    setData(arr)
    localStorage.setItem('items', JSON.stringify(arr))
    dec()
  }

  return (
    <div className='item flex sb'>
      <div className='flex ai_fs'>
        <img alt='' src={item.spec ? specImage(item) : item.image} />
        <div>
          <h4>{item.title}</h4>
          {/* <p>Переходник</p> */}
          <span>ООО “Эгопласт”</span>
          {item.cost && (
            <div className='flex range'>
              <div onClick={() => number > 1 && onChange(number - 1)}>-</div>
              <h3>{number}</h3>
              <div onClick={() => onChange(number + 1)}>+</div>
            </div>
          )}
        </div>
      </div>
      <div className='flex sb ai_fe delete'>
        <h3>{item.cost ? item.cost.toLocaleString('ru') + ' ₽' : 'Под заказ'}</h3>
        <button onClick={removeItem}>Удалить</button>
      </div>
    </div>
  )
}

export default BasketItem
