import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useStore from '../../utils/store'

const Product = ({ item }) => {
  const navigate = useNavigate()
  const getState = () => {
    let items = JSON.parse(localStorage.getItem('items'))
    if (items && items.find((i) => i.id === item.id)) return false
    return true
  }
  const [state, setState] = useState(getState())
  const { inc } = useStore()

  const onClick = (item) => {
    let items = JSON.parse(localStorage.getItem('items'))
    if (items) items.push(item)
    if (!items) items = [item]
    localStorage.setItem('items', JSON.stringify(items))
    setState(false)
    inc()
  }
  return (
    <div className='category product'>
      <img src={item.image} alt='' />
      <h3>{item.title}</h3>
      <div className='flex sb wrap ai_c'>
        <div>
          <h5>Цена:</h5>
          <h3>{item.cost.toLocaleString('ru')} ₽</h3>
        </div>
        <button className={!state ? 'tobasket' : ''} onClick={() => (state ? onClick(item) : navigate('/catalog/basket'))}>
          В корзин{state ? 'у' : 'е'}
        </button>
      </div>
    </div>
  )
}

export default Product
