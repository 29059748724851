import { Carousel, Image } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import useStore from '../../../utils/store'
import { useState } from 'react'
const data = [
  { id: 'landingStage', type: 'pontoon', title: 'Дебаркадер' },
  { id: 'pontoon', type: 'pontoon', title: 'Понтон' },
]

const Pontoons = () => {
  const navigate = useNavigate()
  const [boat, setBoat] = useState(data[0])

  const getState = (item) => {
    const cartItems = JSON.parse(localStorage.getItem('items')) || []
    return !cartItems.some((cartItem) => cartItem.id === item.id)
  }
  const [state, setState] = useState(getState(boat))
  const { inc } = useStore()
  const radioClick = (item) => {
    setState(getState(item))
    setBoat(item)
  }
  const addItemToCart = () => {
    const cartItems = JSON.parse(localStorage.getItem('items')) || []
    const newItem = { ...boat, spec: true }
    const updatedCartItems = [...cartItems, newItem]
    localStorage.setItem('items', JSON.stringify(updatedCartItems))
    setState(false)
    inc()
  }
  return (
    <div className='content'>
      <div className='yetfull flex' style={{ display: 'block' }}>
        <div>
          <Link to='/catalog'>Каталог</Link> — Дебаркадеры, понтоны
        </div>
        <div className='product flex wrap ai_fs'>
          <Image.PreviewGroup>
            <Carousel infinite={false} autoplay>
              <Image src={require('../../../img/catalog/Pontons/ponton4.jpg')} />
              <Image src={require('../../../img/catalog/Pontons/ponton1.jpg')} />
              <Image src={require('../../../img/catalog/Pontons/ponton3.jpg')} />
              <Image src={require('../../../img/catalog/Pontons/ponton2.jpg')} />
              <Image src={require('../../../img/catalog/Pontons/ponton5.jpg')} />
              <Image src={require('../../../img/catalog/Pontons/ponton6.jpg')} />
            </Carousel>
          </Image.PreviewGroup>
          <div className='info'>
            <h1>Дебаркадеры, понтоны</h1>
            <p>Описание</p>
            <div style={{ marginBottom: 20 }}>
              • любые размеры; <br />• индивидуальный заказ;
              <br /> • любая форма по желанию клиента;
            </div>
            <div style={{ gap: 7.5 }} className='flex wrap'>
              {data.map((item, index) => (
                <div key={item.id} className='radio2'>
                  <input
                    defaultChecked={index === 0}
                    id={index}
                    onChange={() => radioClick(item)}
                    type='radio'
                    value={`${item.title}`}
                    name='volume'
                  />
                  <label htmlFor={index}>
                    <span>{item.title}</span>
                  </label>
                </div>
              ))}
            </div>
            <div style={{ marginTop: 25 }} className='flex wrap'>
              <button className={!state ? 'tobasket' : ''} onClick={() => (state ? addItemToCart() : navigate('/catalog/basket'))}>
                В корзин{state ? 'у' : 'е'}
              </button>
              <p style={{ lineHeight: '130%', opacity: 1, fontWeight: '300', margin: 0 }}>
                Связаться с менеджером <br /> по номеру{' '}
                <a className='phone' href='tel:+79142730700'>
                  +7 (914) 273-07-00
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pontoons
