import { useMutation } from '@apollo/client'
import { Checkbox, Select, message } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { SEND_EMAIL } from '../gqls/email/mutation'
import { MaskedInput } from 'antd-mask-input'
const { Option } = Select
const workTypes = [
  'Проектирование/монтаж локального водопровода',
  'Предоставление полного производственного цикла',
  'Ремонт полиэтиленовых труб',
  'Услуги перевозки на собственной технике',
  'Услуги эскваватора',
  'Услуги манипулятора',
  'Услуги фронтального погрузчика',
  'Другие услуги',
]

const Services = () => {
  const [agree, setAgree] = useState(false)
  const [workType, setWorkType] = useState(workTypes[0])

  const [sendEmail, { loading }] = useMutation(SEND_EMAIL, {
    onCompleted: () => message.open({ type: 'success', content: 'Сообщение отправлено! Вам скоро ответят.' }),
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const emailSend = (e) => {
    e.preventDefault()
    const { name, tel, email, address, note } = e.target
    if (!agree) {
      message.open({ type: 'warning', content: 'Необходимо согласие на обработку данных.' })
      return null
    }
    if (!loading && name.value && tel.value && email.value && address.value && note.value) {
      sendEmail({
        variables: {
          data: {
            title: 'Запрос заказа услуг',
            description: `Имя: ${name.value}\nТелефон: ${tel.value}\nПочта: ${email.value}\nАдрес: ${address.value}\nТип работы: ${workType}\nКомментарий:\n"${note.value}".\n\nЗапрос был сделан в окне "Услуги".`,
          },
        },
      })
      e.target.reset()
      tel.value = ''
    } else {
      message.open({ type: 'warning', content: 'Заполните все поля.' })
    }
  }

  return (
    <div className='content'>
      <section className='yetfull'>
        <form onSubmit={emailSend} className='request'>
          <h3 style={{ fontFamily: 'Gilroy', fontWeight: 100 }}>Услуги</h3>
          <div className='flex  ai_fe' style={{ gap: 50 }}>
            <h1 style={{ maxWidth: 420 }}>Заполните бланк вашего заказа услуг</h1>
            <p style={{ fontSize: 20, flexGrow: 2 }}>
              Укажите какие работы будут проводиться, адрес, контактные данные. <br /> Мы вам перезвоним в течении часа.
            </p>
          </div>
          <div style={{ marginBottom: 5 }} className='flex requestform sb wrap ai_fs'>
            <div className='inputs'>
              <label>
                <h5>Ваше Имя</h5>
                <input name='name' />
              </label>
              <label>
                <h5>Ваш телефон</h5>
                <MaskedInput mask='+7(000)000-00-00' name='tel' />
              </label>
              <label>
                <h5>Эл. почта</h5>
                <input name='email' type='email' />
              </label>
            </div>
            <div className='inputs'>
              <label>
                <h5>Адрес</h5>
                <input name='address' />
              </label>
              <label>
                <h5>Тип работ</h5>
                <Select onChange={setWorkType} style={{ width: '100%', fontWeight: 900 }} defaultValue={workTypes[0]}>
                  {workTypes.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </label>
            </div>
            <div className='message'>
              <label>
                <h5>Комментарий</h5>
                <textarea name='note' style={{ height: 100 }}></textarea>
              </label>
            </div>
          </div>
          <div className='flex wrap sb ai_fs'>
            <label className='flex' style={{ gap: 10 }}>
              <Checkbox onChange={(e) => setAgree(e.target.checked)} />
              <p>
                Я согласен(а) на <Link to='/'>обработку персональных данных</Link>
              </p>
            </label>
            <button type='submit'>{loading ? 'Ожидание...' : 'Отправить'}</button>
          </div>
        </form>
      </section>
    </div>
  )
}

export default Services
