import { useQuery } from '@apollo/client'
import { FIND_MANY_URNS } from '../../../gqls/catalog/query'
import Loader from '../../../components/Loader'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useStore from '../../../utils/store'

const Urns = () => {
  const navigate = useNavigate()
  const { data, loading } = useQuery(FIND_MANY_URNS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => radioClick(data.findManyUrns[0]),
  })
  const [choice, setChoice] = useState({})
  const getState = (choice) => {
    let items = JSON.parse(localStorage.getItem('items'))
    if (items && items.find((i) => i.id === choice.id)) return false
    return true
  }
  const [state, setState] = useState(getState(choice))
  const { inc } = useStore()
  const radioClick = (item) => {
    setState(getState(item))
    setChoice(item)
  }
  const onClick = (isOrder = false) => {
    let items = JSON.parse(localStorage.getItem('items'))
    let object = { ...choice }
    object.spec = true
    object.type = 'urn'
    object.title = `Урна ${object.volume}л ${object.sizes}`
    if (isOrder) delete object.cost
    if (items) items.push(object)
    if (!items) items = [object]
    localStorage.setItem('items', JSON.stringify(items))
    setState(false)
    inc()
  }
  return loading ? (
    <Loader />
  ) : (
    <>
      <p>Выберите объем</p>
      <div style={{ gap: 7.5 }} className='flex wrap'>
        {data?.findManyUrns.map((item, index) => (
          <div key={item.id} className='radio2'>
            <input defaultChecked={index === 0} onChange={() => radioClick(item)} id={index} type='radio' name='volume' />
            <label htmlFor={index}>
              <span>{item.volume} - секционная</span>
              <div></div>
              <span>{item.sizes}</span>
            </label>
          </div>
        ))}
      </div>
      <h1 style={{ marginTop: 20, marginBottom: 20 }}>{choice?.cost?.toLocaleString('ru')} РУБ</h1>
      <div className='flex'>
        <button className={!state ? 'tobasket' : ''} onClick={() => (state ? onClick() : navigate('/catalog/basket'))}>
          В корзин{state ? 'у' : 'е'}
        </button>
        {state && (
          <button className='ghost' onClick={() => onClick(true)}>
            Под заказ
          </button>
        )}
      </div>
    </>
  )
}

export default Urns
