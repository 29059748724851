import {
  ClockCircleFilled,
  EnvironmentFilled,
  PhoneFilled,
  MailFilled,
  StarOutlined,
  InstagramOutlined,
  SendOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons'
import { Checkbox, message } from 'antd'
import { Link } from 'react-router-dom'
import { SEND_EMAIL } from '../gqls/email/mutation'
import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import { MaskedInput } from 'antd-mask-input'

const Contacts = () => {
  const [agree, setAgree] = useState(false)
  const [sendEmail, { loading }] = useMutation(SEND_EMAIL, {
    onCompleted: () => message.open({ type: 'success', content: 'Сообщение отправлено! Вам скоро ответят.' }),
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const emailSend = (e) => {
    e.preventDefault()
    const { name, tel, email, question } = e.target
    if (!agree) {
      message.open({ type: 'warning', content: 'Необходимо согласие на обработку данных.' })
      return null
    }
    if (!loading && name.value && tel.value && email.value && question.value) {
      sendEmail({
        variables: {
          data: {
            title: 'Запрос на обратную связь + вопрос (Контакты)',
            description: `Имя: ${name.value}\nТелефон: ${tel.value}\nПочта: ${email.value}\nВопрос:\n"${question.value}".\n\nЗапрос был сделан в окне "Контакты".`,
          },
        },
      })
      e.target.reset()
      tel.value = ''
    } else {
      message.open({ type: 'warning', content: 'Заполните все поля.' })
    }
  }
  return (
    <div className='content'>
      <section className='yetfull' style={{ gap: 20 }}>
        <div className='contact_info'>
          <h1>Контакты</h1>
          <p>Напишите нам, оставляйте свои контакты и мы напишем вам в течении 12 часов.</p>
          <div className='flex'>
            <ClockCircleFilled />
            <p>Понедельник - Пятница: 10:00 - 18:00</p>
          </div>
          <div className='flex'>
            <EnvironmentFilled />
            <p>г. Якутск, ул. Дзержинского 76</p>
          </div>
          <div className='flex'>
            <MailFilled />
            <p>
              <a href='mailto:egotech14@gmail.com'>egotech14@gmail.com</a>
            </p>
          </div>
          <div className='flex'>
            <PhoneFilled />
            <p>
              <a href='tel:+79142747703'>+7 (914) 274-77-03</a>
            </p>
          </div>
          <div className='flex'>
            <PhoneFilled />
            <p>
              <a href='tel:+79142730700'>+7 (914) 273-07-00</a>
            </p>
          </div>
          <div className='flex'>
            <ArrowRightOutlined />
            <p>
              <a target='_blank' rel='noreferrer' href='https://vk.com/technoplastic_egoplast'>
                Вконтакте
              </a>
            </p>
          </div>
          <div className='flex'>
            <SendOutlined />
            <p>
              <a target='_blank' rel='noreferrer' href='https://t.me/technoplastic_ykt'>
                Tелеграмм канал
              </a>
            </p>
          </div>
          <div className='flex'>
            <InstagramOutlined />
            <p>
              <a target='_blank' rel='noreferrer' href='https://instagram.com/egoplast_ykt'>
                egoplast_ykt
              </a>
            </p>
          </div>
          <div className='flex'>
            <StarOutlined />
            <p>
              <a target='_blank' rel='noreferrer' href='https://technoplastik.taplink.ws'>
                Taplink
              </a>
            </p>
          </div>
        </div>
        {/* <div style="position:relative;overflow:hidden;"><a href="https://yandex.kz/maps/74/yakutsk/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Якутск</a><a href="https://yandex.kz/maps/74/yakutsk/house/ulitsa_dzerzhinskogo_76/ZUsPaABkTkQPXEJsYWJxd39qZgA=/?ll=129.747259%2C62.064037&utm_medium=mapframe&utm_source=maps&z=16.01" style="color:#eee;font-size:12px;position:absolute;top:14px;">Улица Дзержинского, 76 — Яндекс Карты</a><iframe src="https://yandex.kz/map-widget/v1/?ll=129.747259%2C62.064037&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoyMTkzODAwODk5EnHQoNC-0YHRgdC40Y8sINCg0LXRgdC_0YPQsdC70LjQutCwINCh0LDRhdCwICjQr9C60YPRgtC40Y8pLCDQr9C60YPRgtGB0LosINGD0LvQuNGG0LAg0JTQt9C10YDQttC40L3RgdC60L7Qs9C-LCA3NiIKDUe_AUMVdkF4Qg%2C%2C&z=16.01" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div> */}
        <div className='mapwrapper'>
          <YMaps
            query={{
              ns: 'use-load-option',
              load: 'Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon',
            }}
          >
            <Map
              defaultState={{
                center: [62.06437, 129.745087],
                zoom: 17,
                controls: ['zoomControl', 'fullscreenControl'],
              }}
              width='100%'
              height='100%'
            >
              <Placemark defaultGeometry={[62.06437, 129.745087]} />
            </Map>
          </YMaps>
        </div>

        {/* <img src={require('../img/map.jpg')} alt='' /> */}
      </section>
      <section>
        <form onSubmit={emailSend} className='request'>
          <h3 style={{ fontFamily: 'Gilroy', fontWeight: 100 }}>Обратная связь</h3>
          <h1 style={{ marginBottom: 30, maxWidth: 480 }}>Оставьте заявку и мы свяжемся с вами</h1>
          <div style={{ marginBottom: 15 }} className='flex requestform sb ai_fs'>
            <div className='inputs'>
              <label>
                <h5>Ваше Имя</h5>
                <input name='name' />
              </label>
              <label>
                <h5>Ваш телефон</h5>
                <MaskedInput mask='+7(000)000-00-00' name='tel' />
              </label>
              <label>
                <h5>Эл. почта</h5>
                <input name='email' type='email' />
              </label>
            </div>
            <label className='message'>
              <h5>Ваш вопрос</h5>
              <textarea name='question'></textarea>
            </label>
          </div>
          <div className='flex sb ai_fs'>
            <label style={{ gap: 10 }} className='flex'>
              <Checkbox onChange={(e) => setAgree(e.target.checked)} />
              <p>
                Я согласен(а) на <Link to='/'>обработку персональных данных</Link>
              </p>
            </label>
            <button type='submit'>{loading ? 'Ожидание...' : 'Отправить'}</button>
          </div>
        </form>
      </section>
    </div>
  )
}

export default Contacts
