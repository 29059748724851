import './App.css'
import './media.css'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import Header from './components/Header'
import Home from './pages/Home'
import Contacts from './pages/Contacts'
import Footer from './components/Footer'
import Services from './pages/Services'
import Egoplast from './pages/Сompanies/Egoplast'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/locale/ru_RU'
import Event from './pages/Events/Event'
import Basket from './pages/Catalog/Basket'
import Index from './pages/Catalog/Index'
import ScrollToTop from './components/ScrollToTop'
import { ApolloProvider } from '@apollo/client'
import client from './utils/apollo'
import Pipes from './pages/Catalog/specific/Pipes'
import Septic from './pages/Catalog/specific/Septic'
import WaterCarriers from './pages/Catalog/specific/WaterCarriers'
import Skids from './pages/Catalog/specific/Skids'
import Pools from './pages/Catalog/specific/Pools'
import Boats from './pages/Catalog/specific/Boats'
import Category from './pages/Catalog/fittings/Catergory'
import SubCategory from './pages/Catalog/fittings/Subcategory'
import Products from './pages/Catalog/fittings/Products'
import { useEffect } from 'react'
import ContainersRoundAndOval from './pages/Catalog/specific/ContainersRoundAndOval'
// import Technoplast from './pages/Сompanies/Technoplast'
import Pontoons from './pages/Catalog/specific/Pontoons'
import Other from './pages/Catalog/specific/Other'

function Loader() {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/', { replace: true })
  }, [navigate])
  return <div>Redirecting...</div>
}

function App() {
  return (
    <ApolloProvider client={client}>
      <ConfigProvider locale={ruRU}>
        <div className='App'>
          <BrowserRouter>
            <ScrollToTop />
            <Header />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/contacts' element={<Contacts />} />
              <Route path='/services' element={<Services />} />
              <Route path='/egoplast' element={<Egoplast />} />
              {/* <Route path='/technoplast' element={<Technoplast />} /> */}
              <Route path='/events/:id' element={<Event />} />
              <Route path='/catalog' element={<Index />} />
              <Route path='/catalog/basket' element={<Basket />} />
              <Route path='/catalog/pipes' element={<Pipes />} />
              <Route path='/catalog/round-and-oval-containers' element={<ContainersRoundAndOval />} />
              <Route path='/catalog/septic' element={<Septic />} />
              <Route path='/catalog/water-carriers' element={<WaterCarriers />} />
              <Route path='/catalog/skids' element={<Skids />} />
              <Route path='/catalog/pools' element={<Pools />} />
              <Route path='/catalog/boats' element={<Boats />} />
              <Route path='/catalog/pontoons' element={<Pontoons />} />
              <Route path='/catalog/other' element={<Other />} />
              <Route path='/catalog/fittings' element={<Category />} />
              <Route path='/catalog/fittings/:id' element={<SubCategory />} />
              <Route path='/catalog/fittings/product/:id' element={<Products />} />
              <Route path='*' element={<Loader />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </div>
      </ConfigProvider>
    </ApolloProvider>
  )
}

export default App
