import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { FIND_MANY_SUB_CATEGORY } from '../../../gqls/catalog/query'
import Loader from '../../../components/Loader'
const Category = () => {
  const { data, loading } = useQuery(FIND_MANY_SUB_CATEGORY, { fetchPolicy: 'network-only' })
  return (
    <div className='content'>
      <div className='yetfull' style={{ display: 'block' }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className='heading'>
              <Link to='/catalog/'>Каталог</Link> — Комплектующие и фитинги
            </div>
            <div style={{ marginTop: 20 }} className='flex wrap ai_n'>
              {data?.findManySubCategory.map((item) => (
                <div key={item.id} className='category'>
                  <img src={item.image} alt='' />
                  <h3>{item.title}</h3>
                  <Link
                    to={
                      item?.subSubCategory.length === 1 ? `/catalog/fittings/product/${item?.subSubCategory[0]?.id}` : `/catalog/fittings/${item.id}`
                    }
                  >
                    Перейти
                  </Link>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Category
