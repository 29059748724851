import { Link, useNavigate } from 'react-router-dom'
import useStore from '../../../utils/store'
import { Carousel, Image } from 'antd'
import { useState } from 'react'
import data from '../../../utils/pipesPrices'
const pipe = { type: 'pipes', spec: true }
const getState = (cost, width) => {
  const items = JSON.parse(localStorage.getItem('items')) || []
  return !items.some((item) => item.cost === cost * width)
}

const Pipes = () => {
  const navigate = useNavigate()
  const [type, setType] = useState(data[0])
  const [choice, setChoice] = useState(data[0].values[0])
  const [width, setWidth] = useState(1)

  const [state, setState] = useState(getState(choice.c, width))
  const { inc } = useStore()

  const getType = (type) => {
    setType(type)
    radioClick(type.values[0])
  }

  const radioClick = (item) => {
    setChoice(item)
    setState(getState(item.c, width))
  }

  const widthHandler = (width) => {
    if (width >= 0 && width <= 10000) {
      setWidth(width)
      setState(getState(choice.c, width))
    }
  }

  const onClick = () => {
    const total = choice.c * width
    if (!total) return
    const items = JSON.parse(localStorage.getItem('items')) || []
    const newitem = { id: 'pipe' + total, title: `Полиэтиленовая труба ${type.type} ${choice.d}мм ${width}м`, cost: total }
    localStorage.setItem('items', JSON.stringify([...items, { ...pipe, ...newitem }]))
    setState(false)
    inc()
  }
  return (
    <div className='content'>
      <div className='yetfull flex' style={{ display: 'block' }}>
        <div>
          <Link to='/catalog'>Каталог</Link> — Полиэтиленовые трубы
        </div>
        <div className='product pipes flex wrap ai_fs'>
          <Carousel infinite={false} autoplay>
            <Image src={require('../../../img/catalog/pipesPrice.png')} style={{ objectFit: 'cover' }} />
          </Carousel>
          <div className='info'>
            <h1>Полиэтиленовые трубы</h1>
            <p>Выберите тип</p>
            <div style={{ gap: 7.5 }} className='flex wrap'>
              {data.map((item, index) => (
                <div key={item.type} className='radio2'>
                  <input defaultChecked={index === 0} onChange={() => getType(item)} id={item.type} type='radio' name='type' />
                  <label htmlFor={item.type}>
                    <span>{item.type}</span>
                  </label>
                </div>
              ))}
            </div>
            <p>Выберите диаметр</p>
            <div style={{ gap: 7.5 }} className='flex wrap'>
              {type.values.map((item, index) => (
                <div key={item.c} className='radio2'>
                  <input defaultChecked={index === 0} onChange={() => radioClick(item)} id={item.c} type='radio' name='value' />
                  <label htmlFor={item.c}>
                    <span>{item.d} мм</span>
                  </label>
                </div>
              ))}
            </div>
            <div>
              <p>Укажите длину (метры)</p>
              <input type='number' name='dlina' min='1' max='10000' value={width} onChange={(e) => widthHandler(e.target.value)} />
            </div>
            <h1 style={{ marginTop: 30, marginBottom: 20 }}>{(choice.c * width).toLocaleString('ru')} РУБ</h1>
            <div className='flex wrap'>
              <button className={!state ? 'tobasket' : ''} onClick={() => (state ? onClick() : navigate('/catalog/basket'))}>
                В корзин{state ? 'у' : 'е'}
              </button>
              <p style={{ lineHeight: '130%', opacity: 1, fontWeight: '300', margin: 0 }}>
                Связаться с менеджером <br /> по номеру{' '}
                <a className='phone' href='tel:+79142730700'>
                  +7 (914) 273-07-00.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pipes
