import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const Loader = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fb9544' }} />} />
    </div>
  )
}

export default Loader
