import { Checkbox, Modal, message } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import BasketItem from '../../components/catalog/BasketItem'
import { useState } from 'react'
import Total from '../../components/catalog/Total'
import { SEND_EMAIL } from '../../gqls/email/mutation'
import { useMutation } from '@apollo/client'
import { CheckCircleOutlined } from '@ant-design/icons'
import useStore from '../../utils/store'

const Basket = () => {
  const items = JSON.parse(localStorage.getItem('items'))
  items?.forEach((item) => (item.count = 1))
  const [data, setData] = useState(items ? items : [])
  const [agree, setAgree] = useState(false)
  const { clear } = useStore()
  const navigate = useNavigate()

  const [sendEmail, { loading }] = useMutation(SEND_EMAIL, {
    onCompleted: () => {
      localStorage.clear()
      clear()
      Modal.confirm({
        title: 'Заказ сформирован!',
        icon: <CheckCircleOutlined />,
        content: 'Спасибо за заказ! Менеджер свяжется с вами в течение часа.',
        onOk: () => navigate('/catalog'),
        okText: 'Закрыть',
        cancelButtonProps: { style: { display: 'none' } },
      })
    },
    onError: (err) => {
      console.error(err)
      message.error('Не удалось выполнить запрос')
    },
  })

  const emailSend = (e) => {
    e.preventDefault()
    const { name, tel, email } = e.target
    if (!loading && agree && name.value && tel.value && email.value && data) {
      let products = ''
      let schet = 1
      let total = 0
      for (let item of data) {
        products += `${schet}. ${item.title} \t\t${item.cost ? item.cost.toLocaleString('ru') + ' ₽' : 'Под заказ'} \t${
          !item.cost ? '' : item.count + ' шт'
        }\n`
        schet++
        if (item.cost) total += item.cost * item.count
      }
      sendEmail({
        variables: {
          data: {
            title: 'Запрос на покупку товаров',
            description: `Имя: ${name.value}\nТелефон: ${tel.value}\nПочта: ${email.value}\n\nТовары:\n${products}\nИтого: ${total.toLocaleString(
              'ru'
            )} ₽`,
          },
        },
      })
      e.target.reset()
    }
  }
  return (
    <div className='content'>
      <section className='yetfull basketpage'>
        <div className='items'>
          {data.map((item, index) => (
            <BasketItem key={item.id} item={item} index={index} data={data} setData={setData} />
          ))}
          {data.length === 0 && (
            <div className='item flex jc_c'>
              <h4>Нет товаров</h4>
            </div>
          )}
        </div>
        <form onSubmit={emailSend} className='request'>
          <h2 style={{ marginBottom: 10 }}>Корзина</h2>
          <p style={{ marginBottom: 10 }}>Оставьте свои данные и наш менеджер свяжется с вами в течении часа.</p>
          <div className='inputs'>
            <label>
              <h4 style={{ marginBottom: 10 }}>Ваше Имя</h4>
              <input name='name' />
            </label>
            <label>
              <h4 style={{ marginBottom: 10 }}>Ваш телефон</h4>
              <input name='tel' type='tel' />
            </label>
            <label>
              <h4 style={{ marginBottom: 10 }}>Эл. почта</h4>
              <input name='email' type='email' />
            </label>
          </div>
          <div>
            <label style={{ gap: 10 }} className='flex'>
              <Checkbox onChange={setAgree} />
              <p>
                Я согласен(а) на <Link to='/'>обработку персональных данных</Link>
              </p>
            </label>
            <button type='submit'>{loading ? 'Ожидание...' : 'Отправить'}</button>
          </div>
          <div>
            <h3>Примерная стоимость</h3>
            <h3 style={{ color: '#3859D6' }}>
              ~ <Total items={data} /> ₽
            </h3>
            <p>Цена рассчитывается приблизительно</p>
          </div>
        </form>
      </section>
    </div>
  )
}

export default Basket
