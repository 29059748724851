import { useQuery } from '@apollo/client'
import { Carousel, Image } from 'antd'
import { FIND_MANY_WATER_CONTAINERS } from '../../../gqls/catalog/query'
import Loader from '../../../components/Loader'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useStore from '../../../utils/store'

const WaterCarriers = () => {
  const navigate = useNavigate()
  const [state, setState] = useState(false)
  const [isSummers, setIsSummers] = useState(true)
  const [choice, setChoice] = useState({})

  const { data, loading } = useQuery(FIND_MANY_WATER_CONTAINERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => radioClick(data.findManyWaterContainers.filter((item) => item.isSummers === isSummers)[0]),
  })

  const getState = (choice) => {
    let items = JSON.parse(localStorage.getItem('items'))
    if (items && items.find((i) => i.id === choice.id)) return false
    return true
  }
  const { inc } = useStore()
  const setType = (isSummers) => {
    setIsSummers(isSummers)
    radioClick(data.findManyWaterContainers.filter((item) => item.isSummers === isSummers)[0])
  }
  const radioClick = (item) => {
    setState(getState(item))
    setChoice(item)
  }
  const onClick = () => {
    let items = JSON.parse(localStorage.getItem('items'))
    let object = { ...choice }
    object.spec = true
    object.type = 'waterCarrier'
    object.title = `Водовозка ${isSummers ? 'летняя' : 'всесезонная'} ${object.volume}л ${object.sizes}`
    if (items) items.push(object)
    if (!items) items = [object]
    localStorage.setItem('items', JSON.stringify(items))
    setState(false)
    inc()
  }
  return (
    <div className='content'>
      <div className='yetfull flex' style={{ display: 'block' }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div>
              <Link to='/catalog'>Каталог</Link> — Водовозки
            </div>
            <div className='product flex wrap ai_fs'>
              <Image.PreviewGroup>
                <Carousel infinite={false} autoplay>
                  <Image src={require('../../../img/catalog/waterCarriers/1.png')} />
                  <Image src={require('../../../img/catalog/waterCarriers/3.jpg')} />
                  <Image src={require('../../../img/catalog/waterCarriers/4.jpg')} />
                  <Image src={require('../../../img/catalog/waterCarriers/2.png')} />
                </Carousel>
              </Image.PreviewGroup>
              <div className='info'>
                <h1>Водовозки</h1>
                <p>Выберите тип</p>
                <div style={{ gap: 7.5 }} className='flex wrap'>
                  <div>
                    <input defaultChecked id='1' type='radio' onChange={() => setType(true)} name='type' />
                    <label htmlFor='1'>Водовозки летние</label>
                  </div>
                  <div>
                    <input id='2' type='radio' onChange={() => setType(false)} name='type' />
                    <label htmlFor='2'>Водовозки всесезонные</label>
                  </div>
                </div>
                <p>Выберите объем</p>
                <div style={{ gap: 7.5 }} className='flex wrap'>
                  {data?.findManyWaterContainers
                    .filter((item) => item.isSummers === isSummers)
                    .map((item, index) => (
                      <div key={item.id} className='radio2'>
                        <input defaultChecked={index === 0} onChange={() => radioClick(item)} id={item.id} type='radio' name='volume' />
                        <label htmlFor={item.id}>
                          <span>{item.volume}л</span>
                          <div></div>
                          <span>{item.sizes}</span>
                        </label>
                      </div>
                    ))}
                </div>
                <h1 style={{ marginTop: 25, marginBottom: 20 }}>{choice?.cost?.toLocaleString('ru')} РУБ</h1>
                <div className='flex wrap'>
                  <button className={!state ? 'tobasket' : ''} onClick={() => (state ? onClick() : navigate('/catalog/basket'))}>
                    В корзин{state ? 'у' : 'е'}
                  </button>
                  <p style={{ lineHeight: '130%', opacity: 1, fontWeight: '300', margin: 0 }}>
                    Для заказа емкостей нестандартных форм обратитесь <br /> к менеджеру по номеру{' '}
                    <a className='phone' href='tel:+79142730700'>
                      +7 (914) 273-07-00
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default WaterCarriers
