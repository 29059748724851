import { useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import { FIND_ONE_SUB_SUB_CATEGORY } from '../../../gqls/catalog/query'
import Loader from '../../../components/Loader'
import Product from '../../../components/catalog/Product'
const Products = () => {
  const { id } = useParams()
  const { data, loading } = useQuery(FIND_ONE_SUB_SUB_CATEGORY, { fetchPolicy: 'network-only', variables: { where: { id } } })
  return (
    <div className='content'>
      <div className='yetfull' style={{ display: 'block' }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className='heading'>
              <Link to='/catalog/'>Каталог</Link> — <Link to='/catalog/fittings'>Комплектующие и фитинги</Link> —{' '}
              <Link to={`/catalog/fittings/${data?.findUniqueSubSubCategory?.subCategory?.id}`}>
                {data?.findUniqueSubSubCategory?.subCategory?.title}
              </Link>{' '}
              — {data?.findUniqueSubSubCategory?.title}
            </div>
            <div style={{ marginTop: 20 }} className='flex wrap ai_n'>
              {data?.findUniqueSubSubCategory?.product.map((item) => (
                <Product item={item} key={item.id} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Products
