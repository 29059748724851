import { gql } from '@apollo/client'

export const FIND_MANY_ALBOM = gql`
  query ($where: AlbomWhereInput) {
    findManyAlbom(where: $where) {
      id
      title
      description
      image
    }
  }
`
